<template>
  <div class="exam-body" @contextmenu.prevent>
    <el-container
      class="exam-body-container"
      id="page"
      @click.native="hidemagnifier"
    >
      <!-- 头部区域 -->
      <el-header style="width: 100%;" @click.native="closeShowDiv">
        <div class="act-logo"></div>
        <div class="exam-next-wrap">
          <el-button
            style="font-weight: bold"
            size="small"
            :disabled="prevDisabled"
            @click="prevClick"
            >Prev</el-button
          >
          <el-button
            style="font-weight: bold"
            size="small"
            @click="navClick"
            :disabled="paginationDisabled"
            >Nav</el-button
          >
          <el-button
            style="font-weight: bold"
            size="small"
            :disabled="nextDisabled"
            @click="nextClick"
            >Next</el-button
          >
        </div>
        <div class="exam-tools-wrap" v-if="isPreview !== 1">
          <el-dropdown
            style="margin-right: 10px;"
            @command="Highlighter"
            :hide-on-click="false"
          >
            <el-button style="font-weight: bold" size="small"
              >Tools<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :icon="checkedTools === 'Magnifier' ? 'el-icon-check' : ''"
                command="Magnifier"
                >Magnifier</el-dropdown-item
              >
              <el-dropdown-item
                :icon="checkedTools === 'Highlighter' ? 'el-icon-check' : ''"
                command="Highlighter"
                >Highlighter
              </el-dropdown-item>
              <el-dropdown-item
                :icon="checkedTools === 'Reader' ? 'el-icon-check' : ''"
                command="Reader"
                >Line Reader</el-dropdown-item
              >
              <el-dropdown-item
                :icon="checkedTools === 'Eliminator' ? 'el-icon-check' : ''"
                command="Eliminator"
              >
                Answer Eliminator
              </el-dropdown-item>
              <el-dropdown-item
                :icon="checkedTools === 'Masking' ? 'el-icon-check' : ''"
                command="Masking"
              >
                Answer Masking
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button-group style="margin-right: 10px;">
            <el-button
              size="small"
              icon="el-icon-zoom-out"
              @click="zoomOut"
            ></el-button>
            <el-button
              size="small"
              icon="el-icon-refresh-right"
              @click="zoomReset"
            ></el-button>
            <el-button
              size="small"
              icon="el-icon-zoom-in"
              @click="zoomIn"
            ></el-button>
          </el-button-group>
          <el-button size="small" @click="clearHight"
            >Clear Highlight</el-button
          >
          <el-button
            style="font-weight: bold"
            size="small"
            icon="el-icon-close"
            @click="endExam"
            >End Section
          </el-button>
          <el-button
            size="small"
            v-if="totalTime === 0 || totalTime === leftTime"
            icon="el-icon-time"
          ></el-button>
          <div class="exam-time-body" v-else>
            <div
              class="exam-time-bg"
              :style="'width: ' + leftTimePer + '%;'"
            ></div>
            <p class="exam-time">{{ leftTimeFormat }}</p>
          </div>
        </div>
      </el-header>
      <!-- 内容区域 -->
      <el-container id="Highlighter" class="Highlighter">
        <!-- 左侧文章区域 -->
        <el-aside
          id="Aside"
          :width="step === 1 || step === 2 ? '60%' : '53%'"
          v-if="questionType !== 'math'"
        >
          <template v-if="step === 1 || step === 2">
            <p class="info-title" v-if="inObj.title">{{ inObj.title }}</p>
            <p class="article-content" v-html="formatBr(inObj.text)"></p>
          </template>
          <template v-else>
            <p class="article-title" v-if="articleTitle">{{ articleTitle }}</p>
            <p class="article-name" v-if="articleName">{{ articleName }}</p>
            <p
              class="article-content exam-editor"
              v-if="articleContent"
              v-html="formatBr(articleContent)"
            ></p>
          </template>
        </el-aside>
        <!-- 右侧选项区域 -->
        <el-main @click.native="closeShowDiv">
          <div class="question-title">
            <p class="question-title-key" v-if="questionNumber">
              {{ questionNumber }}.
            </p>
            <p
              :class="[
                'question-title-content',
                'exam-editor',
                questionType == 'math' ? 'max60' : ''
              ]"
              v-if="questionContent"
              v-html="questionContent"
            ></p>
          </div>

          <ul>
            <li
              class="answer-item"
              :class="{ 'text-none': item.isMasking }"
              v-for="item in questionItemList"
              :key="`question-item-${item.key}`"
            >
              <input
                type="radio"
                v-model="answer"
                :value="item.key.toLowerCase()"
                :disabled="isPreview === 1 || item.eliminator || item.isMasking"
                @change="checkOption"
              />
              <span
                class="question-item-key"
                :class="{ 'text-decoration': item.eliminator }"
                @click="optionClick(item)"
              >
                {{ item.key }}.
              </span>
              <p
                class="question-item-content exam-editor question-inline-content"
                :class="{
                  'text-decoration': item.eliminator
                }"
                @click="optionClick(item)"
                v-html="item.value"
              ></p>
            </li>
          </ul>

          <div
            class="question-solution-wrap"
            v-if="isPreview === 1 && step === 3"
          >
            <p class="question-solution-title">
              答案解析：<span
                class="question-solution-callback"
                @click="callback"
                >反馈</span
              >
            </p>
            <ul class="question-solution-list">
              <li class="question-solution-item">
                你的答案：
                <span>{{ answer.toUpperCase() || "-" }}</span>
              </li>
              <li class="question-solution-item">
                正确答案：
                <span>{{ rightAnswer.toUpperCase() }}</span>
              </li>
            </ul>

            <p
              id="question-solution-content"
              class="question-solution-content exam-editor"
              v-html="solution"
              style="color: transparent; text-shadow: 0 0 5px rgba(0,0,0,0.5); user-select:none ;cursor:pointer;"
              @click="hideStyle"
              @mouseover="showmode"
              @mouseout="hidemode"
            ></p>

            <span
              id="question-solution-mode"
              style=" color:#409EFF;display: none;font-size: 10px"
            >
              点击查看解析内容
            </span>

            <p class="question-solution-tips">
              * 解析可能用到的选项对应关系：F=A, G=B, H=C, J=D, K=E
            </p>
          </div>
          <el-card
            shadow="never"
            v-if="isPreview === 1 && step === 3"
            style="margin-top: 20px"
          >
            <p class="question-solution-title">分析笔记</p>
            <el-input
              style="margin: 0 0 10px 0;position: static;"
              type="textarea"
              :rows="4"
              maxlength="500"
              show-word-limit
              placeholder="写下分析笔记的题⽬将⾃动被收藏(上限500字)"
              v-model="analysis.analysis"
            >
            </el-input>
            <el-button type="primary" @click="addAnalysisDetailed">{{
              analysis.id ? "修改" : "保存"
            }}</el-button>
          </el-card>
        </el-main>
      </el-container>
      <!-- 底部按钮区域 -->
      <el-footer
        v-show="questionIdList.length > 0"
        @click.native="closeShowDiv"
      >
        <div class="footer-pagination">
          <el-button
            type="text"
            icon="el-icon-arrow-left"
            :disabled="leftIndex == 0 || paginationDisabled"
            class="h28"
            style="position:absolute;left: 10px;"
            @click="left"
          ></el-button>
          <!--          <span style="min-width: 0px !important; font-size: 10px;vertical-align: sub;">-->
          <el-button
            size="small"
            type="text"
            :disabled="step !== 1"
            @click="goToInfo"
            style="min-width: 0px !important; font-size: 10px"
            class="h28"
            >Info
          </el-button>
          <el-button
            size="small"
            type="text"
            :disabled="step === 1"
            @click="goToInstr"
            style="min-width: 0px !important; font-size: 10px"
            class="h28"
            >Instr
          </el-button>
          <!--          </span>-->
          <div class="page-scroll" :style="{ width: showNum * 30 + 'px' }">
            <el-pagination
              class="page-item"
              :style="{ left: leftNum + 'px' }"
              background
              layout="pager"
              :total="questionTotal"
              :pager-count="999"
              :page-size="1"
              :current-page.sync="currentPage"
              :disabled="paginationDisabled"
              @current-change="currentChange"
              @prev-click="paginationPrevClick"
              @next-click="paginationNextClick"
            >
            </el-pagination>
          </div>
          <el-button
            type="text"
            icon="el-icon-arrow-right"
            :disabled="
              leftIndex + showNum == questionTotal || paginationDisabled
            "
            class="h28"
            @click="right"
          ></el-button>
        </div>
        <div
          class="footer-btn"
          v-if="isPreview !== 1 && [1, 2].indexOf(step) === -1"
        >
          <el-button icon="el-icon-wind-power" @click="setFlag">Flag</el-button>
        </div>
      </el-footer>
    </el-container>

    <!-- 结束考试确认框 -->
    <el-dialog title="End Section" :visible.sync="dialogVisible" width="50%">
      <p class="end-tips-p">
        To end the section, press the End Section button.
      </p>
      <p class="end-tips-sub">
        There are <span>{{ unAnsweredCount }}</span> unanswered question(s).
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="endExamReq">End Test</el-button>
        <el-button @click="dialogVisible = false">Close</el-button>
      </span>
    </el-dialog>

    <!-- 自动交卷确认框 -->
    <el-dialog
      :visible.sync="dialogVisible2"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      top="20%"
      width="200px"
      custom-class="previewDialog"
    >
      <span style="display: inline-block;text-align: center;width:100%;">
        time up
      </span>
    </el-dialog>

    <!-- nav -->
    <div v-if="showNav" class="nav-body" @click="closeShowDiv">
      <el-dialog
        title="questionIdList"
        :visible.sync="showNav"
        :destroy-on-close="true"
      >
        <el-table
          :data="navList"
          :row-class-name="setRowClass"
          :highlight-current-row="false"
          @row-click="rowClick"
        >
          <el-table-column label="#" width="80" prop="number"></el-table-column>
          <el-table-column label="State" width="150">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.userAnswer.length > 0 ? "Answered" : "Unanswered"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Flagged">
            <template slot-scope="scope">
              <el-checkbox
                v-if="['info', 'instr'].indexOf(scope.row.number) === -1"
                :checked="scope.row.userFlag === 1"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>

    <!--提示-->
    <el-dialog
      title="反馈"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <el-input
        type="textarea"
        :rows="4"
        maxlength="100"
        show-word-limit
        placeholder="发现了排版错误或者录入瑕疵，请写下来告诉我们，我们会第一时间解决。’反馈‘功能不能回复，所以请勿提问。"
        v-model="call.content"
      >
      </el-input>
      <el-radio
        style="margin-top: 20px"
        v-model="call.feedbackType"
        v-for="(item, index) in feedbackTypeList"
        :key="index"
        :label="index"
        v-if="index"
        >{{ item }}
      </el-radio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="callSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <div id="mouse" class="version_total" @click="closeShowDiv"></div>
    <div
      id="magnifier"
      tabindex="3000"
      class="ui-draggable-handle"
      style="left: 335px; top: 140px; display: block;"
      v-show="checkedTools === 'Magnifier'"
    >
      <div id="mag_viewport" style="width: 100%; height: 100%;">
        <div
          id="mag_content"
          style="transform: scale(1.2) translate(-352.06px, -172.577px);"
        >
          <el-container class="exam-body-container" id="page">
            <!-- 头部区域 -->
            <el-header style="width: 100%;" @click.native="closeShowDiv">
              <div class="act-logo"></div>
              <div class="exam-next-wrap">
                <el-button
                  style="font-weight: bold"
                  size="small"
                  :disabled="prevDisabled"
                  @click="prevClick"
                  >Prev</el-button
                >
                <el-button
                  style="font-weight: bold"
                  size="small"
                  @click="navClick"
                  :disabled="paginationDisabled"
                  >Nav</el-button
                >
                <el-button
                  style="font-weight: bold"
                  size="small"
                  :disabled="nextDisabled"
                  @click="nextClick"
                  >Next</el-button
                >
              </div>
              <div class="exam-tools-wrap" v-if="isPreview !== 1">
                <el-dropdown
                  style="margin-right: 10px;"
                  @command="Highlighter"
                  :hide-on-click="false"
                >
                  <el-button style="font-weight: bold" size="small"
                    >Tools<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :icon="
                        checkedTools === 'Magnifier' ? 'el-icon-check' : ''
                      "
                      command="Magnifier"
                      >Magnifier</el-dropdown-item
                    >
                    <el-dropdown-item
                      :icon="
                        checkedTools === 'Highlighter' ? 'el-icon-check' : ''
                      "
                      command="Highlighter"
                      >Highlighter
                    </el-dropdown-item>
                    <el-dropdown-item
                      :icon="checkedTools === 'Reader' ? 'el-icon-check' : ''"
                      command="Reader"
                      >Line Reader</el-dropdown-item
                    >
                    <el-dropdown-item
                      :icon="
                        checkedTools === 'Eliminator' ? 'el-icon-check' : ''
                      "
                      command="Eliminator"
                    >
                      Answer Eliminator
                    </el-dropdown-item>
                    <el-dropdown-item
                      :icon="checkedTools === 'Masking' ? 'el-icon-check' : ''"
                      command="Masking"
                    >
                      Answer Masking
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button-group style="margin-right: 10px;">
                  <el-button
                    size="small"
                    icon="el-icon-zoom-out"
                    @click="zoomOut"
                  ></el-button>
                  <el-button
                    size="small"
                    icon="el-icon-refresh-right"
                    @click="zoomReset"
                  ></el-button>
                  <el-button
                    size="small"
                    icon="el-icon-zoom-in"
                    @click="zoomIn"
                  ></el-button>
                </el-button-group>
                <el-button size="small" @click="clearHight"
                  >Clear Highlight</el-button
                >
                <el-button
                  style="font-weight: bold"
                  size="small"
                  icon="el-icon-close"
                  @click="endExam"
                  >End Section
                </el-button>
                <el-button
                  size="small"
                  v-if="totalTime === 0 || totalTime === leftTime"
                  icon="el-icon-time"
                ></el-button>
                <div class="exam-time-body" v-else>
                  <div
                    class="exam-time-bg"
                    :style="'width: ' + leftTimePer + '%;'"
                  ></div>
                  <p class="exam-time">{{ leftTimeFormat }}</p>
                </div>
              </div>
            </el-header>
            <!-- 内容区域 -->
            <el-container id="Highlighter" class="Highlighter">
              <!-- 左侧文章区域 -->
              <el-aside
                id="Aside"
                :width="step === 1 || step === 2 ? '60%' : '53%'"
                v-if="questionType !== 'math'"
              >
                <template v-if="step === 1 || step === 2">
                  <p class="info-title" v-if="inObj.title">{{ inObj.title }}</p>
                  <p class="article-content" v-html="formatBr(inObj.text)"></p>
                </template>
                <template v-else>
                  <p class="article-title" v-if="articleTitle">
                    {{ articleTitle }}
                  </p>
                  <p class="article-name" v-if="articleName">
                    {{ articleName }}
                  </p>
                  <p
                    class="article-content exam-editor"
                    v-if="articleContent"
                    v-html="formatBr(articleContent)"
                  ></p>
                </template>
              </el-aside>
              <!-- 右侧选项区域 -->
              <el-main @click.native="closeShowDiv">
                <div class="question-title">
                  <p class="question-title-key" v-if="questionNumber">
                    {{ questionNumber }}.
                  </p>
                  <p
                    :class="[
                      'question-title-content',
                      'exam-editor',
                      questionType == 'math' ? 'max60' : ''
                    ]"
                    v-if="questionContent"
                    v-html="questionContent"
                  ></p>
                </div>

                <ul>
                  <li
                    class="answer-item"
                    :class="{ 'text-none': item.isMasking }"
                    v-for="item in questionItemList"
                    :key="`question-item-${item.key}`"
                  >
                    <input
                      type="radio"
                      v-model="answer"
                      :value="item.key.toLowerCase()"
                      :disabled="
                        isPreview === 1 || item.eliminator || item.isMasking
                      "
                      @change="checkOption"
                    />
                    <span
                      class="question-item-key"
                      :class="{ 'text-decoration': item.eliminator }"
                      @click="optionClick(item)"
                    >
                      {{ item.key }}.
                    </span>
                    <p
                      class="question-item-content exam-editor question-inline-content"
                      :class="{
                        'text-decoration': item.eliminator
                      }"
                      @click="optionClick(item)"
                      v-html="item.value"
                    ></p>
                  </li>
                </ul>

                <div
                  class="question-solution-wrap"
                  v-if="isPreview === 1 && step === 3"
                >
                  <p class="question-solution-title">
                    答案解析：<span
                      class="question-solution-callback"
                      @click="callback"
                      >反馈</span
                    >
                  </p>
                  <ul class="question-solution-list">
                    <li class="question-solution-item">
                      你的答案：
                      <span>{{ answer.toUpperCase() || "-" }}</span>
                    </li>
                    <li class="question-solution-item">
                      正确答案：
                      <span>{{ rightAnswer.toUpperCase() }}</span>
                    </li>
                  </ul>

                  <p
                    id="question-solution-content"
                    class="question-solution-content exam-editor"
                    v-html="solution"
                    style="color: transparent; text-shadow: 0 0 5px rgba(0,0,0,0.5); user-select:none ;cursor:pointer;"
                    @click="hideStyle"
                    @mouseover="showmode"
                    @mouseout="hidemode"
                  ></p>

                  <span
                    id="question-solution-mode"
                    style=" color:#409EFF;display: none;font-size: 10px"
                  >
                    点击查看解析内容
                  </span>

                  <p class="question-solution-tips">
                    * 解析可能用到的选项对应关系：F=A, G=B, H=C, J=D, K=E
                  </p>
                </div>
                <el-card
                  shadow="never"
                  v-if="isPreview === 1 && step === 3"
                  style="margin-top: 20px"
                >
                  <p class="question-solution-title">分析笔记</p>
                  <el-input
                    style="margin: 0 0 10px 0;position: static;"
                    type="textarea"
                    :rows="4"
                    maxlength="500"
                    show-word-limit
                    placeholder="写下分析笔记的题⽬将⾃动被收藏(上限500字)"
                    v-model="analysis.analysis"
                  >
                  </el-input>
                  <el-button type="primary" @click="addAnalysisDetailed">{{
                    analysis.id ? "修改" : "保存"
                  }}</el-button>
                </el-card>
              </el-main>
            </el-container>
            <!-- 底部按钮区域 -->
            <el-footer
              v-show="questionIdList.length > 0"
              @click.native="closeShowDiv"
            >
              <div class="footer-pagination">
                <el-button
                  type="text"
                  icon="el-icon-arrow-left"
                  :disabled="leftIndex == 0 || paginationDisabled"
                  class="h28"
                  style="position:absolute;left: 10px;"
                  @click="left"
                ></el-button>
                <!--          <span style="min-width: 0px !important; font-size: 10px;vertical-align: sub;">-->
                <el-button
                  size="small"
                  type="text"
                  :disabled="step !== 1"
                  @click="goToInfo"
                  style="min-width: 0px !important; font-size: 10px"
                  class="h28"
                  >Info
                </el-button>
                <el-button
                  size="small"
                  type="text"
                  :disabled="step === 1"
                  @click="goToInstr"
                  style="min-width: 0px !important; font-size: 10px"
                  class="h28"
                  >Instr
                </el-button>
                <!--          </span>-->
                <div
                  class="page-scroll"
                  :style="{ width: showNum * 30 + 'px' }"
                >
                  <el-pagination
                    class="page-item"
                    :style="{ left: leftNum + 'px' }"
                    background
                    layout="pager"
                    :total="questionTotal"
                    :pager-count="999"
                    :page-size="1"
                    :current-page.sync="currentPage"
                    :disabled="paginationDisabled"
                    @current-change="currentChange"
                    @prev-click="paginationPrevClick"
                    @next-click="paginationNextClick"
                  >
                  </el-pagination>
                </div>
                <el-button
                  type="text"
                  icon="el-icon-arrow-right"
                  :disabled="
                    leftIndex + showNum == questionTotal || paginationDisabled
                  "
                  class="h28"
                  @click="right"
                ></el-button>
              </div>
              <div
                class="footer-btn"
                v-if="isPreview !== 1 && [1, 2].indexOf(step) === -1"
              >
                <el-button icon="el-icon-wind-power" @click="setFlag"
                  >Flag</el-button
                >
              </div>
            </el-footer>
          </el-container>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
document.onkeydown = () => {
  if (window.event.ctrlKey && window.event.keyCode == 70) {
    return false;
    //屏蔽Shift+F10
  }

  if (window.event.metaKey && window.event.keyCode == 70) {
    return false;
    //屏蔽Shift+F10
  }
};

import qs from "qs";
import $ from "jquery";
import { getParams } from "../../assets/js/util";
import { findIndex } from "lodash";

export default {
  data() {
    return {
      showmodeflag: true,
      questionTotal: 0,
      currentPage: 0,
      dialogVisible: false,
      dialogVisible2: false,
      examId: 1,
      userId: 0,
      step: 1,
      questionIdList: [],
      currentQuestionId: 0,
      info: {
        title: "",
        text: ""
      },
      instr: {
        title: "",
        text: ""
      },
      articleTitle: "",
      articleName: "",
      articleContent: "",
      questionNumber: "",
      questionContent: "",
      questionItemList: [],
      answer: "",
      rightAnswer: "",
      canSendAnswer: false,
      solution: "",
      showNav: false,
      totalTime: 0,
      leftTime: 0,
      isPreview: 0,
      questionType: "",
      zoomSize: 1,
      centerDialogVisible: false,
      call: {
        content: "",
        feedbackType: 1
      },
      feedbackTypeList: ["", "文章", "图片", "题目", "解析", "其他"],
      analysis: {},
      leftNum: "0",
      leftIndex: 0,
      showNum: 20,
      checkedTools: "",
      r: "",
      questionItemListPre: []
    };
  },
  watch: {
    $route() {
      this.$router.go(0);
    }
  },
  computed: {
    leftTimeFormat() {
      const { totalTime, leftTime } = this;
      const remainTime = totalTime - leftTime;
      if (remainTime === 0) {
        return "00:00";
      }

      let remainMin = parseInt(remainTime / 60);
      let remainSec = remainTime % 60;

      if (remainMin < 10) {
        remainMin = `0${remainMin}`;
      }

      if (remainSec < 10) {
        remainSec = `0${remainSec}`;
      }
      return `${remainMin}:${remainSec}`;
    },
    leftTimePer() {
      if (this.totalTime === 0) {
        return 0;
      } else {
        return (
          parseInt(
            ((this.totalTime - this.leftTime) / this.totalTime) * 10000
          ) / 100
        );
      }
    },
    unAnsweredCount() {
      let number = 0;
      this.questionIdList.forEach(item => {
        if (item.userAnswer.length === 0) {
          number += 1;
        }
      });
      return number;
    },
    paginationDisabled() {
      let result = true;
      if (this.step === 1 || this.step === 2) {
        result === true;
      } else {
        result = false;
      }

      return result;
    },
    inObj() {
      const step = this.step;
      if (step === 1) {
        return this.info;
      } else {
        return this.instr;
      }
    },
    prevDisabled() {
      let result = false;
      if (this.step === 1) {
        result = true;
      }
      return result;
    },
    nextDisabled() {
      let result = true;
      let currentIndex = 0;
      this.questionIdList.forEach((question, index) => {
        if (question.id === this.currentQuestionId) {
          currentIndex = index;
        }
      });
      result = currentIndex === this.questionIdList.length - 1;
      return result;
    },
    navList() {
      return [
        {
          number: "info",
          userAnswer: "answer",
          userFlag: 0
        },
        {
          number: "instr",
          userAnswer: "answer",
          userFlag: 0
        },
        ...this.questionIdList
      ];
    }
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.examId = decodeURIComponent(query.examId);
    this.userId = decodeURIComponent(query.userId);
    this.step = Number(decodeURIComponent(query.step));
    this.currentQuestionId = decodeURIComponent(query.questionId)
      ? Number(decodeURIComponent(query.questionId))
      : 0;
    this.isPreview = Number(query.isPreview || 0);

    this.getQuestionIdList();

    if (this.step === 1) {
      this.getInfo();
    } else if (this.step === 2) {
      this.getInstr();
      this.getStarInstr();
    } else {
      this.getQuestionDetail(this.currentQuestionId);
    }
  },
  methods: {
    hidemode() {
      if (this.showmodeflag) {
        $("#question-solution-mode").hide();
      }
    },
    showmode() {
      if (this.showmodeflag) {
        $("#question-solution-mode").show();
      }
    },
    hideStyle() {
      $("#question-solution-mode").hide();
      $(".question-solution-content").removeAttr("style");
      this.showmodeflag = false;
    },
    rangyInit() {
      rangy.init();
      this.r = this.r || rangy.createHighlighter();
      //创建高亮节点
      this.r.addClassApplier(
        rangy.createClassApplier("highlight", {
          ignoreWhiteSpace: true,
          tagNames: ["span", "a"]
        })
      );
      $("#Highlighter").mousedown(e => {
        if (this.checkedTools === "Highlighter") {
          let n = !1,
            i = rangy.getSelection(),
            r = i.getAllRanges();
          $.each(r, function(t, i) {
            var r = i.getNodes();
            $.each(r, function(t, i) {
              i.nodeType == 3 && (i = i.parentNode),
                $(i).hasClass("highlight") && (n = !0);
            });
          });
          n
            ? this.r.unhighlightSelection()
            : this.r.highlightSelection("highlight", {
                containerElementId: "Highlighter"
              });
        }
      });
      $("#Aside").mousedown(e => {
        if (this.checkedTools === "Reader") {
          var showDiv = document.getElementById("mouse");
          showDiv.style.left = "40px";
          showDiv.style.top = e.pageY - 20 + "px";
          showDiv.style.display = "block";
          showDiv.innerHTML = e.target.innerHTML;
        }
      });
    },
    closeShowDiv() {
      var showDiv = document.getElementById("mouse");
      showDiv.innerHTML = "";
      showDiv.style.display = "none";
    },
    Highlighter(item) {
      if (item === this.checkedTools) {
        this.checkedTools = "";
      } else {
        this.checkedTools = item;
      }
    },
    clearHight() {
      this.r.removeAllHighlights();
    },
    //反馈
    callback() {
      this.call.content = "";
      this.call.feedbackType = 1;
      this.centerDialogVisible = true;
    },
    callSubmit() {
      const url = "/addFeedbackContent";
      let params = {
        content: this.call.content,
        feedbackType: this.call.feedbackType,
        examId: this.examId,
        questionId: this.currentQuestionId,
        expand1: this.userId,
        id: null,
        createdId: null,
        updatedId: null
      };
      this.$http({
        method: "POST",
        url,
        headers: { "content-type": "application/json" },
        data: params
      }).then(res => {
        const {
          data: { data: result, status }
        } = res;
        if (status === 0) {
          this.centerDialogVisible = false;
          this.$message.success("反馈成功");
        }
      });
    },
    startTime(item) {
      const { id } = item;
      try {
        const url = "/getQuestion";
        this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            examId: this.examId,
            questionId: id
          })
        }).then(res => {
          const {
            data: { data: result, status }
          } = res;
          if (status === 0 || status === 200) {
            this.totalTime = result.totalTime;
            this.leftTime = Math.min(result.leftTime, result.totalTime);

            this.initLeftTimerInterval();

            this.$nextTick(() => {
              this.editDom();
            });
          } else {
            // console.error();
          }
        });
      } catch (err) {
        console.warn(err);
      }
    },
    setRowClass({ row }) {
      let result = "";
      if (row.number === "info") {
        result = "row-type-info";
      } else if (row.number === "instr") {
        result = "row-type-instr";
      } else {
        result = "row-type-default";
      }
      return result;
    },
    rowClick(row, column, event) {
      if (row.number === "info") {
        return false;
      }

      if (row.number === "instr") {
        this.$router.push(
          `/exam?examId=${this.examId}&userId=${this.userId}&step=2&isPreview=${this.isPreview}`
        );
        return false;
      }
      this.$router.push({
        path: "/exam",
        query: {
          examId: this.examId,
          userId: this.userId,
          step: 3,
          questionId: row.id,
          isPreview: this.isPreview
        }
      });
    },
    formatBr(content) {
      // return content.replace(/(\r\n|\n|\r)/g, "<br/>");
      return content;
    },
    endExam() {
      this.dialogVisible = true;
    },
    goToInfo() {
      if (this.step === 1) return;
      this.$router.push(
        `/exam?examId=${this.examId}&userId=${this.userId}&step=1&isPreview=${this.isPreview}`
      );
    },
    goToInstr() {
      if (this.step === 2) return;
      this.$router.push(
        `/exam?examId=${this.examId}&userId=${this.userId}&step=2&isPreview=${this.isPreview}`
      );
    },
    currentChange(val) {
      this.initFlag();
      this.$router.push({
        path: "/exam",
        query: {
          examId: this.examId,
          userId: this.userId,
          step: 3,
          questionId: this.questionIdList[val - 1].id,
          isPreview: this.isPreview
        }
      });
    },
    left() {
      let all = this.questionTotal - this.showNum;
      let now = this.leftIndex - Math.round(all / 5);
      this.leftIndex = now <= 0 ? 0 : now;
      this.leftNum = -this.leftIndex * 30;

      // let num = Math.round(this.questionTotal/10);
      // let page = this.currentPage - num < 1 ? 1 : this.currentPage - num;
      // this.currentPage = page;
      // this.currentChange(page);
    },
    right() {
      let all = this.questionTotal - this.showNum;
      let now = this.leftIndex + Math.round(all / 5);
      this.leftIndex = now >= all ? all : now;
      this.leftNum = -this.leftIndex * 30;
      // let num = Math.round(this.questionTotal/10);
      // let page = this.currentPage + num > this.questionTotal ? this.questionTotal : this.currentPage + num;
      // this.currentPage = page;
      // this.currentChange(page);
    },
    prevClick() {
      const step = this.step;
      if (step === 2) {
        this.$router.push({
          path: "/exam",
          query: {
            examId: this.examId,
            userId: this.userId,
            step: 1,
            isPreview: this.isPreview
          }
        });
      } else if (step === 3) {
        let currentIndex = -1;
        this.questionIdList.forEach((question, index) => {
          if (question.id === this.currentQuestionId) {
            currentIndex = index;
          }
        });
        if (currentIndex === 0) {
          this.$router.push({
            path: "/exam",
            query: {
              examId: this.examId,
              userId: this.userId,
              step: 2,
              isPreview: this.isPreview
            }
          });
        } else {
          this.$router.push({
            path: "/exam",
            query: {
              examId: this.examId,
              userId: this.userId,
              step: 3,
              questionId: this.questionIdList[currentIndex - 1].id,
              isPreview: this.isPreview
            }
          });
        }
      }
    },
    navClick() {
      this.showNav = true;
    },
    nextClick() {
      const step = this.step;
      if (step === 1) {
        this.$router.push({
          path: "/exam",
          query: {
            examId: this.examId,
            userId: this.userId,
            step: 2,
            isPreview: this.isPreview
          }
        });
      } else if (step === 2) {
        this.$router.push({
          path: "/exam",
          query: {
            examId: this.examId,
            userId: this.userId,
            step: 3,
            questionId: this.questionIdList[0].id,
            isPreview: this.isPreview
          }
        });
      } else if (step === 3) {
        let currentIndex = -1;
        this.questionIdList.forEach((question, index) => {
          if (question.id === this.currentQuestionId) {
            currentIndex = index;
          }
        });
        this.$router.push({
          path: "/exam",
          query: {
            examId: this.examId,
            userId: this.userId,
            step: 3,
            questionId: this.questionIdList[currentIndex + 1].id,
            isPreview: this.isPreview
          }
        });
      }
    },
    storageQuestionItem() {
      // 存储选项
      const index = findIndex(
        this.questionItemListPre,
        item => item.questionId === this.currentQuestionId
      );
      if (index > -1) {
        this.questionItemListPre[
          index
        ].questionItemList = this.questionItemList;
      } else {
        this.questionItemListPre.push({
          questionId: this.currentQuestionId,
          questionItemList: this.questionItemList
        });
      }
      sessionStorage.setItem(
        "questionItemListPre",
        JSON.stringify(this.questionItemListPre)
      );
    },
    paginationPrevClick(val) {
      console.log("paginationPrevClick", val);
    },
    paginationNextClick(val) {
      console.log("paginationNextClick", val);
    },
    zoomOut() {
      this.zoomSize = this.zoomSize - 0.25;
      document.body.style.zoom = this.zoomSize;
      this.editDom();
    },
    zoomReset() {
      this.zoomSize = 1;
      document.body.style.zoom = this.zoomSize;
      this.editDom();
    },
    zoomIn() {
      this.zoomSize = this.zoomSize + 0.25;
      document.body.style.zoom = this.zoomSize;
      this.editDom();
    },
    async getQuestionIdList() {
      try {
        const url = "/getQuestionIdList";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            examId: this.examId
          })
        });

        if (status === 0) {
          // console.log("result: " + result);
          this.questionIdList = result;
          this.questionTotal = result.length;

          if (this.step == 2) {
            this.startTime(result[0]);
          }

          if (this.currentQuestionId !== 0) {
            this.questionIdList.forEach((question, index) => {
              if (question.id === this.currentQuestionId) {
                this.currentPage = index + 1;
              }
            });
            this.$nextTick(() => {
              this.initFlag();
              this.initAnswered();
            });
          }
          this.$nextTick(() => {
            this.editDom();
          });
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    //修改跳页
    editDom() {
      let dom = document.querySelector(".el-footer").offsetWidth;
      this.showNum = Math.floor(dom / 30 - 10);
      this.leftIndex =
        this.currentPage - this.showNum <= 0
          ? 0
          : this.currentPage - this.showNum;
      this.leftNum = -this.leftIndex * 30;

      try {
        // let parent = document.querySelector('.el-pager');
        // let doms = document.querySelectorAll('.el-pager .more');
        // let pare = document.createElement('li')
        // pare.className = "el-icon-more";
        // pare.style.width = '35px';
        // pare.style.position = 'relative';
        // pare.style.overflow = 'hidden';
        //
        // pare.innerHTML = `<span style="position: absolute;top: -100px;">${this.currentPage}</span>`;
        // doms.forEach(n=>{
        //   parent.replaceChild(pare,n);
        // })
      } catch (e) {}
    },
    async getQuestionDetail(currentQuestionId) {
      try {
        const url = "/getQuestion";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            examId: this.examId,
            questionId: currentQuestionId
          })
        });

        if (status === 0) {
          this.articleTitle = result.articleTitle;
          this.articleName = result.articleName;
          this.articleContent = result.articleContent;
          this.questionNumber = result.questionNumber;
          this.questionContent = result.questionContent;
          this.questionItemList = result.questionItemList;
          this.answer = result.userAnswer;
          this.rightAnswer = result.answer;
          this.totalTime = result.totalTime;
          this.leftTime = Math.min(result.leftTime, result.totalTime);
          this.solution = result.solution;
          this.questionType = result.questionType;
          // 如果在本地有存储则用本地的，主要是保留tools里的Eliminator和masking功能
          if (sessionStorage.getItem("questionItemListPre")) {
            const cont = JSON.parse(
              sessionStorage.getItem("questionItemListPre")
            );
            const index = findIndex(
              cont,
              item => item.questionId === currentQuestionId
            );
            if (index > -1) {
              this.questionItemList = cont[index].questionItemList;
            }
            this.questionItemListPre = cont;
          }
          setTimeout(() => {
            this.canSendAnswer = true;
          }, 100);

          if (this.isPreview !== 1) {
            this.initLeftTimerInterval();
          }
          this.getAnalysisDetailed();
          this.$nextTick(() => {
            this.editDom();
          });
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    //保存笔记分析
    addAnalysisDetailed() {
      let url;
      if (this.analysis.id) {
        url = "/modifyAnalysisDetailed";
      } else {
        url = "/addAnalysisDetailed";
      }
      let params = {
        id: this.analysis.id,
        examId: this.examId,
        questionId: this.currentQuestionId,
        expand1: this.userId,
        createdDatetime: "",
        createdId: 0,
        expand2: "",
        expand3: "",
        expand4: "",
        expand5: "",
        pageNum: 10,
        pageSize: 10,
        updatedDatetime: "",
        updatedId: 10,
        userId: 10, //this.userId
        ...this.analysis,
        analysis: this.analysis.analysis
      };
      this.$http({
        method: "POST",
        url,
        headers: { "content-type": "application/json" },
        data: params
      }).then(res => {
        const {
          data: { data: result, status }
        } = res;
        if (status === 0) {
          this.$message.success(this.analysis.id ? "修改成功" : "保存成功");
          this.getAnalysisDetailed();
        }
      });
    },
    //查新笔记分析
    getAnalysisDetailed() {
      const url = "/getAnalysisDetailed";
      let params = {
        examId: this.examId,
        questionId: this.currentQuestionId,
        expand1: this.userId
      };
      this.$http({
        method: "POST",
        url,
        headers: { "content-type": "application/json" },
        data: params
      }).then(res => {
        const {
          data: { data: result, status }
        } = res;
        if (status === 0) {
          if (result.length) this.analysis = result[0];
          // this.centerDialogVisible = false;
          // this.$message.success('反馈成功');
        }
      });
    },
    initLeftTimerInterval() {
      if (this.timer) {
        clearInterval(this.timer);
      } else {
        this.timer = setInterval(() => {
          if (this.leftTime < this.totalTime) {
            this.leftTime += 1;
          } else {
            clearInterval(this.timer);
            // 直接结束考试，无需确认
            this.endExamReq();
            //this.timeIsOut();
          }
        }, 1000);
      }
    },
    // timeIsOut() {
    //   this.$alert("考试时间到", "", {
    //     confirmButtonText: "确定",
    //     callback: () => {
    //       this.endExamReq();
    //     }
    //   });
    // },
    async getInfo() {
      try {
        const url = "/getInfo";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            examId: this.examId
          })
        });

        if (status === 0) {
          this.info = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStarInstr() {
      try {
        const url = "/getStarInstr";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            examId: this.examId
          })
        });

        if (status === 0) {
          // this.info = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInstr() {
      try {
        const url = "/getInstr";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            examId: this.examId
          })
        });

        if (status === 0) {
          this.instr = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sendAnswer(answer) {
      try {
        const url = "/sendAnswer";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            questionId: this.currentQuestionId,
            username: this.userId,
            examId: this.examId,
            answer
          })
        });

        if (status === 0 && result.isSuccess === 1) {
          this.getQuestionIdList();
        } else {
          this.$message({
            message: "答题失败",
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
        this.$message({
          message: "答题失败",
          type: "error"
        });
      }
    },
    initFlag() {
      const liListEle = document.querySelectorAll(".el-pager li");
      const questionIdList = this.questionIdList;

      liListEle.forEach(liEle => {
        const contentIndex = Number(liEle.innerText);
        if (contentIndex > 0) {
          if (questionIdList[contentIndex - 1].userFlag === 1) {
            liEle.classList.add("number-flag");
          } else {
            liEle.classList.remove("number-flag");
          }
        }
      });
    },
    initAnswered() {
      const liListEle = document.querySelectorAll(".el-pager li");
      const questionIdList = this.questionIdList;

      liListEle.forEach(liEle => {
        const contentIndex = Number(liEle.innerText);
        if (contentIndex > 0) {
          if (questionIdList[contentIndex - 1].userAnswer.length > 0) {
            liEle.classList.add("number-answered");
          } else {
            liEle.classList.remove("number-answered");
          }
        }
      });
    },
    async setFlag() {
      const questionIdList = this.questionIdList;
      let currentQuestionIndex = -1;
      questionIdList.forEach((questionItem, index) => {
        if (questionItem.id === this.currentQuestionId) {
          currentQuestionIndex = index;
        }
      });
      const currentFlag = this.questionIdList[currentQuestionIndex].userFlag;
      try {
        const url = "/setFlag";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            questionId: this.currentQuestionId,
            username: this.userId,
            examId: this.examId,
            flag: (currentFlag + 1) % 2
          })
        });

        if (status === 0 && result.isSuccess === 1) {
          this.questionIdList[currentQuestionIndex].userFlag =
            (currentFlag + 1) % 2;
          this.$forceUpdate();
          this.initFlag();
          this.getQuestionIdList();
        } else {
          this.$message({
            message: "flag失败",
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
        this.$message({
          message: "flag失败",
          type: "error"
        });
      }
    },
    async endExamReq() {
      this.dialogVisible = false;
      // this.dialogVisible2 = true;
      try {
        const url = "/endExam";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            username: this.userId,
            examId: this.examId
          })
        });

        // 单科目考试
        if (status === 0 && !result.actAllSubjectTesting) {
          this.$router.push({
            path: "/result",
            query: {
              examId: this.examId,
              userId: this.userId
            }
          });
        } else if (status === 0 && result.actAllSubjectTesting) {
          // 多科目考试
          if (result.actType == "english" || result.actType == "reading") {
            this.$router.push(
              `/exam?examId=${result.nextExamId}&userId=${this.userId}&step=2&isPreview=0`
            );
          } else if (result.actType == "math") {
            this.$router.push(
              `/break?actId=${result.actId}&userId=${this.userId}&type=3&actAllSubjectTestingId=${result.actAllSubjectTestingId}`
            );
          } else if (
            result.actType == "science" &&
            result.actAllSubjectTesting
          ) {
            this.$router.push({
              path: "/actAllSubjectTestingResult",
              query: {
                examId: this.examId,
                actAllSubjectTestingId: result.actAllSubjectTestingId
              }
            });
          }
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    optionClick(item) {
      if (this.checkedTools === "Eliminator") {
        if (item.eliminator) {
          this.$set(item, "eliminator", false);
        } else {
          this.$set(item, "eliminator", true);
          if (item.key.toLowerCase() === this.answer) {
            this.answer = "";
            this.sendAnswer(this.answer);
          }
        }
      } else if (this.checkedTools === "Masking") {
        if (item.isMasking) {
          this.$set(item, "isMasking", false);
        } else {
          this.$set(item, "isMasking", true);
          if (item.key.toLowerCase() === this.answer) {
            this.answer = "";
            this.sendAnswer(this.answer);
          }
        }
      } else {
        if (!item.eliminator && !item.isMasking) {
          this.answer = item.key.toLowerCase();
          if (this.isPreview !== 1 && this.answer && this.canSendAnswer) {
            this.sendAnswer(this.answer);
          }
        }
      }
      this.storageQuestionItem();
    },
    checkOption() {
      if (this.isPreview !== 1 && this.answer && this.canSendAnswer) {
        this.sendAnswer(this.answer);
      }
    },
    hidemagnifier() {
      if (this.checkedTools === "Magnifier") {
        this.checkedTools = "";
      }
    },
    dragg() {
      const box1 = document.getElementById("magnifier");
      $("#magnifier").mousedown(e => {
        const event = e || window.event;
        // 调整鼠标和div的位置问题
        // div的偏移量=鼠标.clentx -元素.offsetLeft
        // div的偏移量=鼠标.clentY -元素.offsetTop
        var ol = event.clientX - box1.offsetLeft;
        var ot = event.clientY - box1.offsetTop;

        // onmousemove应该在onmousedown里面，点击之后才会进行拖拽
        //鼠标移动函数： 为document绑定onmousemove
        document.onmousemove = function(event) {
          event = event || window.event;
          var scrollTop =
            document.body.scrollTop || document.documentElement.scrollTop;
          var scrollLeft =
            document.body.scrollLeft || document.documentElement.scrollLeft;

          var x = event.clientX + scrollLeft - ol;
          var y = event.clientY + scrollTop - ot;

          var isRTL = $("body").css("direction") == "rtl";

          var pw = $("body").width();
          var ph = $("body").height();

          var magw = $("#magnifier").width();
          var magh = $("#magnifier").height();

          var magblw = $("#magnifier").css("borderLeftWidth");
          var magbrw = $("#magnifier").css("borderRightWidth");
          var magbtw = $("#magnifier").css("borderTopWidth");
          var magbbw = $("#magnifier").css("borderBottomWidth");
          magblw = parseInt(magblw, 10);
          magbrw = parseInt(magbrw, 10);
          magbtw = parseInt(magbtw, 10);
          magbbw = parseInt(magbbw, 10);

          var maxX = pw - magw - magblw - magbrw;
          var maxY = ph - magh - magbtw - magbbw;

          if (x < 0) x = 0;
          else if (x > maxX) x = maxX;
          if (y < 0) y = 0;
          else if (y > maxY) y = maxY;

          //设置 box1的位置
          box1.style.left = x + "px";
          box1.style.top = y + "px";

          var factor = 1.2;

          var percentX = x / maxX;
          if (isRTL) percentX = 1 - percentX;

          var posX = (pw - magw / factor) * percentX;
          var posY = ((ph - magh / factor) * y) / maxY;

          if (isRTL) {
            posX = posX - pw / factor;
            posY = -posY;
          } else {
            posX = -posX;
            posY = -posY;
          }

          $("#mag_content").css(
            "transform",
            "scale(" + factor + ") translate(" + posX + "px, " + posY + "px)"
          );
        };

        // 鼠标松开:  为document绑定onmouseup
        document.onmouseup = function() {
          // 直接取消doument的onmousemove事件
          document.onmousemove = null;
          // 直接取消doument的onmouseup事件
          //  防止鼠标在其大地放松改的时候也触发该函数
          document.onmouseup = null;
          // alert("鼠标松开了");
        };
      });
    }
  },
  mounted() {
    this.rangyInit();
    document.addEventListener("copy", function(e) {
      e.preventDefault();
      //window.getSelection(0).toString()
      let copyTxt = `不允许复制哦~`;
      if (e.clipboardData) {
        e.clipboardData.setData("text/plain", copyTxt);
      } else if (window.clipboardData) {
        return window.clipboardData.setData("text", copyTxt);
      }
    });
    this.$nextTick(() => {
      this.dragg();
      $("#mag_viewport").css("width", $("#page").outerWidth(true) + "px");
      $("#mag_viewport").css("height", $("#page").outerHeight(true) + "px");
      $(window).on("resize", () => {
        $("#mag_viewport").css("width", $("#page").outerWidth(true) + "px");
        $("#mag_viewport").css("height", $("#page").outerHeight(true) + "px");
      });
    });
  }
};
</script>
<style lang="stylus">
/* --- Magnifier --- */
#magnifier {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 3px solid black;
  border-radius: 50%;
  display: none;
  overflow: hidden;
  background-color: #eaf5f7;
  z-index: 2000;
}
#mag_viewport {
  position: absolute;
}
#mag_content {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.2);
  transform-origin: left top;
}
#mag_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.2);
  z-index: 2001;
  opacity: 0;
  transform-origin: left top;
}
.ui-draggable-handle {
  cursor: move;
}
.version_total{
  position: absolute;
  max-width: 45%;
  padding: 10px
  height: auto
  max-height: 300px
  overflow: auto
  border-radius: 8px;
  background-color:#fff;
  border:1px solid black
  text-align: left
  display:none
}
.question-inline-content{
  display: inline
}
.question-inline-content p{
  display: inline
}
.text-none{
  opacity: 0
}
.previewDialog.el-dialog {
  .el-dialog__header {
    display: none;
  }

  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
}

.question-solution-content exam-editor {

}

.yellow {
  background: #ffff00;
}

.highlight {
  background: #ffff00;
}

body {
  background #fff
}

.nav-body
  .el-dialog__body
    height: 70vh;
    overflow-y: auto;

.exam-body {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.row-type-info
  cursor: not-allowed;
  color: #999 !important;
  background: #e8e8e8 !important;


.exam-body-container {
  width: 100%;
  height: 100%;
}

.el-header {
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 30;
  background: #001733;
  display flex;
  align-items: center;
  padding: 0 20px;

  .act-logo {
    width: 92px;
    height: 25px;
    flex-shrink: 0;
    background: url('./../../assets/img/logo.png') 0 0 no-repeat;
    margin-right: 20px;
  }

  .exam-next-wrap {
    flex: 1;
  }
}

.exam-time-body
  display: inline-block;
  position relative
  width: 80px;
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;

  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  font-weight: 500;
  margin-left: 10px;
  over-flow hidden


.exam-time-bg
  position absolute
  width: 100%
  height 100%
  top 0
  left 0
  background #ddd
  z-index 20


.exam-time
  font-weight bold
  font-size 14px
  position relative
  z-index 30
  display flex
  align-items center
  justify-content center

.el-aside {
  padding: 90px 0px 80px 40px !important;
}

.el-container
  height: 100vh;

.el-main {
  padding: 90px 20px 80px 40px !important;
}

.el-footer {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important;
  background: #fafafa;
  display flex;
  align-items: center;
  box-sizing border-box
  padding: 30px 20px !important;

  .footer-pagination {
    flex: 1;
    display: flex;
    align-items: center;

    .h28 {
      height 28px
      padding: 0;
    }

    .page-scroll {
      overflow: hidden;
      position: relative;
      display: inline-block;
      margin-left: 10px;
      padding-top: 26px;
      top: -13px;

      .page-item {
        //position absolute
        left: 0;
      }
    }
  }

  .footer-btn {
    flex-shrink: 0;
  }
}

.el-pagination
  padding 0 !important
  position relative
  display: inline-block;

  .btn-prev, .btn-next
    opacity: 0;
    z-index: -1;

.footer-pagination .el-pagination.is-background .el-pager .number
  background #ffffff
  border 1px solid #dddddd
  margin 0 !important

.footer-pagination .el-pagination.is-background .el-pager .number.number-answered
  background #e8e8e8

.footer-pagination .el-pagination.is-background .el-pager .number.active
  background #409EFF

.el-pagination button, .el-pagination span:not([class*=suffix])
  font-size: 10px !important;
  min-width: 0px !important;

.number-flag
  position relative

  &::after {
    content: "\e6fd";
    position: absolute;
    font-family: element-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    vertical-align: baseline;
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #606266;
    top: -26px;
    display: flex;
    justify-content: center;
  }

.end-tips-p
  font-size 15px
  font-weight: bold;
  color #333
  margin: 0 0 15px 0;

.end-tips-sub
  font-size 15px
  color #333
  margin: 0 0 15px 0;
  padding 10px 8px
  background rgb(254, 240, 240)
  border-radius 4px
  color #e42a22

  span
    color #e42a22
    font-weight bold

.info-title
  font-size 18px
  font-weight bold
  color #333
  margin-bottom 20px

.article-title
  font-size 14px
  font-weight bold
  color #333

.article-name
  font-size 13px
  font-weight bold
  color #333
  margin: 8px 0

.article-content
  font-size 14px
  color #333
  line-height 21px

.question-title
  display flex
  font-size 14px
  font-weight bold
  color #333
  line-height 21px
  margin-bottom 20px
  padding-left 9px

  .question-title-key
    font-weight normal

  .question-title-content
    flex 1
    white-space normal
    margin-left 5px
    font-weight normal

  .max60
    max-width 60%

.answer-item
  cursor: pointer
  padding-left 5px
  font-size 14px
  color #333
  line-height 21px
  margin-bottom 30px
  .text-decoration{
    text-decoration: line-through;
    text-decoration-color: red
  }
  .text-decoration p{
    text-decoration: line-through;
    text-decoration-color: red
  }

  .el-radio__inner
    margin-top 4px

  .el-radio__label
    display flex
    font-size 14px
    color #333
    line-height 21px

    .question-item-key
      font-weight normal
      display inline-block

    .question-item-content
      flex 1
      white-space normal
      margin-left 5px
      font-weight normal

  .el-radio
    display flex

.question-solution-title
  font-size: 20px;
  color: #333;
  margin-bottom: 8px;
  font-weight bold

.question-solution-wrap
  background: #EBEEF5;
  padding: 30px;
  border-radius: 10px;
  position: relative;

  .question-solution-callback
    cursor: pointer;
    float: right;

  //position: absolute;
  //right: 40px;
  //top: 15px;
  //font-weight: bolder;
  //font-size: 17px;

  //.question-solution-title
  //  font-size: 20px;
  //  color: #333;
  //  margin-bottom: 8px;
  //  font-weight bold

  .question-solution-list
    display flex
    margin-bottom: 8px;

    .question-solution-item
      span
        color #409EFF

    .question-solution-item + .question-solution-item
      margin-left 30px

  .question-solution-content
    margin-top 10px

  .question-solution-tips
    margin-top 20px
    color #666

.exam-editor
  color: #333;
  line-height: 21px;

  p + p
    margin-top 20px

  img
    vertical-align middle
    margin 0 5px

  u
    text-decoration none
    border-bottom 1px solid #333
    padding-bottom 3px

  u.upper-line
    text-decoration none
    border-top 1px solid #333
    padding-top 0px
    border-bottom none
    padding-bottom 3px
</style>
