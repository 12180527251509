<template>
  <div class="cont-body" v-loading="loading">
    <el-row style="margin-top: 32px">
      <el-button type="primary" @click="newOpen">新增文章</el-button>
    </el-row>
    <el-table :data="tableData" style="width: 100%; margin-top: 24px" border>
      <el-table-column prop="name" label="Passage"> </el-table-column>
      <el-table-column prop="type" label="Sort" width="150px">
      </el-table-column>
      <el-table-column prop="words" label="Words" width="80px">
      </el-table-column>
      <el-table-column label="Notes" width="220px">
        <template slot-scope="scope">
          <span class="introduce" :title="scope.row.introduce">{{
            scope.row.introduce
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80px">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            type="text"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加或修改文章对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="900px"
      append-to-body
      @close="cancel"
    >
      <el-form label-width="80px">
        <el-form-item label="Passage" prop="passage">
          <el-input v-model="name" placeholder="请输入passage" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-input v-model="type" placeholder="请输入类型" />
        </el-form-item>
        <el-form-item label="文章内容">
          <vueEditor v-model="content" :height="300"></vueEditor>
        </el-form-item>
        <el-form-item label="文章介绍" prop="introduce">
          <el-input v-model="introduce" placeholder="请输入文章介绍" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定 </el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      tableData: [],
      title: "新增文章",
      open: false,
      name: "",
      content: "",
      introduce: "",
      type: "",
      id: "",
      loading: false
    };
  },
  methods: {
    async getReadList() {
      this.loading = true;
      try {
        const url = "/read/list";
        const {
          data: { data: tableData, status }
        } = await this.$http({
          url,
          method: "GET"
        });

        if (status === 0) {
          this.tableData = tableData;
        } else {
          this.$message.error("获取问题失败");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    newOpen() {
      this.open = true;
    },
    handleEdit(index, row) {
      this.title = "修改文章";
      this.name = row.name;
      this.content = row.content;
      this.introduce = row.introduce;
      this.id = row.id;
      this.type = row.type;
      this.open = true;
    },
    async submitForm() {
      if (!this.name) {
        this.$message.warning("请输入passage");
        return;
      }
      if (!this.type) {
        this.$message.warning("请输入类型");
        return;
      }
      if (!this.content) {
        this.$message.warning("请输入内容");
        return;
      }
      if (!this.introduce) {
        this.$message.warning("请输入文章介绍");
        return;
      }
      this.loading = true;
      try {
        const url = this.id ? "/read/update" : "/read/save";
        const {
          data: { status }
        } = await this.$http({
          url,
          data: qs.stringify({
            name: this.name,
            content: this.content,
            introduce: this.introduce,
            type: this.type,
            id: this.id ? this.id : undefined
          }),
          method: "POST"
        });

        if (status === 0) {
          this.cancel();
          this.loading = false;
          this.getReadList();
        } else {
          this.$message.error("保存失败");
          this.loading = false;
        }
      } catch (err) {
        this.$message.error("保存失败");
        this.loading = false;
      }
    },
    cancel() {
      this.title = "新增文章";
      this.name = "";
      this.content = "";
      this.introduce = "";
      this.type = "";
      this.id = "";
      this.open = false;
    }
  },
  created() {
    this.getReadList();
  }
};
</script>

<style lang="stylus" scoped>
.cont-body{
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff
}
/deep/ .el-form{
  padding-right: 0
}
.introduce{
  display: inline-block;
  width: 208px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
</style>
