<template>
  <div class="edit-question-body">
    <div class="edit-question-title">
      <h2>同步解析和答案</h2>
      <p></p>
    </div>
    <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="ACT：" prop="actId">
        <el-select v-model="form.actId" placeholder="请选择ACT">
          <el-option
            v-for="item in actList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型：" prop="actType">
        <el-select v-model="form.actType" placeholder="请选择ACT类型">
          <el-option key="1" label="ENGLISH" value="ENGLISH"></el-option>
          <el-option key="2" label="MATH" value="MATH"></el-option>
          <el-option key="3" label="READING" value="READING"></el-option>
          <el-option key="4" label="SCIENCE" value="SCIENCE"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="syncQuestion" :loading="getLoading">同步答案解析</el-button>
      </el-form-item>
    </el-form>
    <editor-component
      ref="editorEle"
      v-model="propContent"
      @sure="handleSure"
    ></editor-component>
  </div>
</template>
<script>

import EditorComponent from "../../components/EditorComponent";
import qs from "qs";

export default {
  data() {
    return {
      propContent: "",
      propKey: "",
      getLoading: false,
      saveLoading: false,
      form: {
        actId: "",
        actType: "",
        questionNumber: ""
      },
      actList: []
    };
  },
  components: { EditorComponent },
  created() {
    this.getActList();
  },
  methods: {
    editArticleItem(key) {
      console.log(key);
      this.propKey = key;
      this.propContent = this.articleForm[key];
      this.$refs.editorEle.show();
    },
    handleSure(content) {
      this.articleForm = {
        ...this.articleForm,
        ...{
          [this.propKey]: content
        }
      };
      this.propKey = "";
      this.propContent = "";
    },
    async getActList() {
      try {
        const url = "/getAllActList";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: {}
        });
        console.log("getAllActList", result, status);

        if (status === 0) {
          this.actList = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async syncQuestion() {
      try {
        const url = "/admin/syncSubjectQuestionByActId";
        let subjectId = 1;
        if (this.form.actType === "ENGLISH") {
          subjectId = 1;
        }else if (this.form.actType === "MATH") {
          subjectId = 2;
        }else if (this.form.actType === "READING") {
          subjectId = 3;
        }else if (this.form.actType === "SCIENCE") {
          subjectId = 4;
        }

        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          data: qs.stringify({
            actId: this.form.actId,
            subjectId: subjectId
          })
        });
        console.log("syncSubjectQuestionByActId", result, status);

        if (status === 0) {
          this.actList = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
};
</script>

<style lang="stylus">
.edit-question-body {
  width: 1180px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}


.edit-question-title {
  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.el-form{
  padding-right: 300px;
  }

.el-select {
  display: block!important;
}

.el-input.is-disabled .el-input__inner
  color: black !important

.el-form-item__content
  p
    color: #333;

  p+p
    margin-top 20px

  img
    vertical-align middle
    margin 0 5px

  u
    text-decoration none
    border-bottom 1px solid #333
    padding-bottom 3px

  u.upper-line
    text-decoration none
    border-top 1px solid #333
    padding-top 0px
    border-bottom none
    padding-bottom 3px
</style>
