<template>
  <div class="act-list-body">
    <div class="act-title">
      <h2>ACT考试历史记录</h2>
      <p></p>
    </div>

    <el-form ref="form" :model="form" label-width="55px">
      <el-form-item label="类型：" prop="actType">
        <el-select v-model="form.actType" placeholder="请选择ACT类型" width="100px" @change="getExamList">
          <el-option label="ENGLISH" value="ENGLISH"></el-option>
          <el-option label="MATH" value="MATH"></el-option>
          <el-option label="READING" value="READING"></el-option>
          <el-option label="SCIENCE" value="SCIENCE"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <main class="act-main">
      <el-table v-loading="loading" :data="examList" border style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="ACT" width="150" align="center"></el-table-column>
        <el-table-column label="科目" width="100" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.type.toUpperCase() }}</p>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" width="200" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.startAt | getTimestamp }}</p>
          </template>
        </el-table-column>
        <el-table-column label="考试用时" width="100" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.spendTime | formatDuration }}</p>
          </template>
        </el-table-column>
        <el-table-column label="正确题数" width="100" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.rightQuestions }}</p>
          </template>
        </el-table-column>
        <el-table-column label="分数" width="100" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.score }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="查看结果" align="center">
          <template slot-scope="scope">
            <el-button @click="gotoSingleResult(scope.row)" type="text" size="small">
              单项结果
            </el-button>
            <el-button @click="gotoActAllSubjectTestingResult(scope.row)" type="text" size="small"
                       v-if="scope.row.actAllSubjectTestingId !== 0">
              Composite结果
            </el-button>
            <el-button @click="gotoDeleteResult(scope.row)" type="text" size="small"
                       v-if="scope.row.actAllSubjectTestingId == 0 && scope.row.rightQuestions <=10">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </main>

    <div class="act-pagination" v-if="examList.length > 0">
      <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :pager-count="21"
          :hide-on-single-page="true"
          @current-change="currentChange"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import qs from "qs";
import {getParams, formatDiffDate} from "../../assets/js/util";

export default {
  data() {
    return {
      userId: 0,
      examList: [],
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      loading: true,
      form: {
        actId: "",
        actType: "",
        questionNumber: ""
      }
    };
  },
  components: {},
  filters: {
    formatDuration(duration) {
      const minute = parseInt(duration / 60);
      const second = duration % 60;
      console.log(minute+":"+second)
      return `${minute > 10 ? minute : `0${minute}`}:${
          second >= 10 ? second : `0${second}`
      }`;
    },
    getTimestamp(time) {
      if (time === null) {
        return "-";
      }
      return formatDiffDate(new Date(time).getTime() / 1000, "-", true);
    }
  },
  created() {
    const query = getParams(window.location.href);
    this.userId = query.userId;

    this.getExamList();
  },
  methods: {
    async getExamList() {
      try {
        const url = "/getExamList";
        const {
          data: {data: examListResult, status}
        } = await this.$http({
          url,
          data: qs.stringify({
            pageNumber: this.pageNumber,
            username: this.userId,
            type:this.form.actType
          }),
          method: "POST"
        });
        console.log("result", examListResult);

        if (status === 0) {
          this.examList = examListResult.list;
          this.total = examListResult.total;
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    currentChange(val) {
      this.pageNumber = val;
      this.getExamList();
    },
    gotoActAllSubjectTestingResult(row) {
      console.log("row", row);
      this.$router.push({
        path: "/actAllSubjectTestingResult",
        query: {
          examId: 0,
          userId: this.userId,
          actAllSubjectTestingId: row.actAllSubjectTestingId
        }
      });
    },
    gotoSingleResult(row) {
      console.log("row", row);
      this.$router.push({
        path: "/result",
        query: {
          examId: row.id,
          userId: this.userId
        }
      });
    },
     gotoDeleteResult(row){
      console.log("row", row);
      this.$confirm('这个单项⼤部分题⽬你都没有做，要删除这次记录吗？',"删除考试记录").then(_ => {
         this.deleteExamById(row.id)
      }).catch(_ => {
        console.log("2");
      })
    },
    async deleteExamById(id){

      try {
        const url = "/deleteExamById";
        const {
          data: {data: result, status}
        } = await this.$http({
          url,
          data: qs.stringify({
            id: id
          }),
          method: "POST"
        });
        console.log("result", result);

        if (status === 0) {
          this.getExamList();
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.act-list-body {
  width: 1180px;
  min-height 100vh
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}

.act-title {

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.act-main {
  margin-top: 40px;
}

.act-pagination {
  margin-top: 20px;
}
</style>
