<template>
  <div class="read-body" v-loading="loading">
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 32px"
      border
      @cell-click="cellClick"
      :cell-class-name="cellName"
    >
      <el-table-column prop="name" label="Passage"> </el-table-column>
      <el-table-column prop="type" label="Sort" width="150px">
      </el-table-column>
      <el-table-column prop="words" label="Words" width="80px">
      </el-table-column>
      <el-table-column label="Notes" width="220px">
        <template slot-scope="scope">
          <span class="introduce" :title="scope.row.introduce">{{
            scope.row.introduce
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      loading: false
    };
  },
  methods: {
    async getReadList() {
      this.loading = true;
      try {
        const url = "/read/list";
        const {
          data: { data: tableData, status }
        } = await this.$http({
          url,
          method: "GET"
        });

        if (status === 0) {
          this.tableData = tableData;
        } else {
          this.$message.error("获取问题失败");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    cellClick(row, column) {
      if (column.property === "name") {
        this.$router.push(`/readArticle/readDetail?id=${row.id}`);
      }
    },
    cellName(data) {
      if (data.column.property === "name") {
        return "passage-name";
      }
    }
  },
  created() {
    this.getReadList();
  }
};
</script>

<style lang="stylus" scoped>
.read-body{
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff
}
/deep/.passage-name .cell{
  color: #409EFF;
  cursor pointer
}
.introduce{
  display: inline-block;
  width: 208px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
</style>
