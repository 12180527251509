<template>
  <div class="exam-body">
    <el-container class="exam-body-container">
      <!-- 头部区域 -->
      <el-header>
        <div class="act-logo"></div>
        <div class="exam-next-wrap">
          <el-button size="small" @click="prevClick">Prev</el-button>
          <el-button size="small" @click="nextClick">Next</el-button>
        </div>
      </el-header>
      <!-- 内容区域 -->
      <el-container>
        <!-- 左侧文章区域 -->
        <el-aside
          :width="step === 1 || step === 2 ? '60%' : '50%'"
          v-if="questionType !== 'math'"
        >
          <template v-if="step === 1 || step === 2">
            <p class="info-title" v-if="inObj.title">{{ inObj.title }}</p>
            <p class="article-content" v-html="formatBr(inObj.text)"></p>
          </template>
          <template v-else>
            <p class="article-title" v-if="articleTitle">{{ articleTitle }}</p>
            <p class="article-name" v-if="articleName">{{ articleName }}</p>
            <p
              class="article-content exam-editor"
              v-if="articleContent"
              v-html="formatBr(articleContent)"
            ></p>
          </template>
        </el-aside>
        <!-- 右侧选项区域 -->
        <el-main>
          <div class="question-title">
            <p class="question-title-key" v-if="questionNumber">
              {{ questionNumber }}.
            </p>
            <p
              class="question-title-content exam-editor"
              v-if="questionContent"
              v-html="questionContent"
            ></p>
          </div>

          <ul>
            <li
              class="answer-item"
              v-for="item in questionItemList"
              :key="`question-item-${item.key}`"
            >
              <el-radio
                v-model="answer"
                :label="item.key.toLowerCase()"
                :disabled="isPreview === 1"
              >
                <span class="question-item-key">{{ item.key }}.</span>
                <p
                  class="question-item-content exam-editor"
                  v-html="item.value"
                ></p>
              </el-radio>
            </li>
          </ul>

          <div class="question-solution-wrap" v-if="isPreview === 0">
            <p class="question-solution-title">答案解析：</p>
            <ul class="question-solution-list">
              <li class="question-solution-item">
                正确答案：
                <span>{{ rightAnswer ==null?'':rightAnswer.toUpperCase() }}</span>
              </li>
            </ul>

            <p
              class="question-solution-content exam-editor"
              v-html="solution"
            ></p>

            <p class="question-solution-tips">
              * 解析可能用到的选项对应关系：F=A, G=B, H=C, J=D, K=E
            </p>
          </div>
        </el-main>
      </el-container>
      <!-- 底部按钮区域 -->
    </el-container>
  </div>
</template>

<script>
import qs from "qs";
import { getParams } from "../../assets/js/util";

export default {
  data() {
    return {
      questionTotal: 0,
      currentPage: 0,
      dialogVisible: false,
      step: 3,
      questionIdList: [],
      currentQuestionId: 0,
      info: {
        title: "",
        text: ""
      },
      instr: {
        title: "",
        text: ""
      },
      articleTitle: "",
      articleName: "",
      articleContent: "",
      questionNumber: "",
      questionContent: "",
      questionItemList: [],
      answer: "",
      rightAnswer: "",
      canSendAnswer: false,
      solution: "",
      showNav: false,
      totalTime: 0,
      leftTime: 0,
      isPreview: 0,
      questionType: ""
    };
  },
  watch: {
    $route() {this.$router.go(0);}
  },
  computed: {
    inObj() {
      const step = this.step;
      if (step === 1) {
        return this.info;
      } else {
        return this.instr;
      }
    }
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.actId = decodeURIComponent(query.actId);
    this.actType = decodeURIComponent(query.actType);
    this.questionNumber = decodeURIComponent(query.questionNumber) ? Number(decodeURIComponent(query.questionNumber)) : 0;

    this.getQuestionDetail();
  },
  methods: {
    formatBr(content) {
      return content;
    },
    prevClick() {
      this.questionNumber = this.questionNumber - 1;
      this.getQuestionDetail();
    },
    nextClick() {
      this.questionNumber = this.questionNumber + 1;
      this.getQuestionDetail();
    },
    async getQuestionDetail() {
      try {
        const url = "/admin/getQuestion";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            actId: this.actId,
            actType: this.actType,
            questionNumber: this.questionNumber
          })
        });

        console.log(result)
        if (status === 0) {
          this.articleTitle = result.articleTitle;
          this.articleName = result.articleName;
          this.articleContent = result.articleContent;
          this.questionNumber = result.questionNumber;
          this.questionContent = result.questionContent;
          this.questionItemList = result.questionItemList;
          this.rightAnswer = result.answer;
          this.totalTime = result.totalTime;
          this.leftTime = Math.min(result.leftTime, result.totalTime);
          this.solution = result.solution;
          this.questionType = result.questionType;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="stylus">
body{
  background  #fff
}

.nav-body
  .el-dialog__body
    height: 70vh;
    overflow-y: auto;

.exam-body {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.row-type-info
  cursor: not-allowed;
  color: #999!important;
  background: #e8e8e8!important;


.exam-body-container {
  width: 100%;
  height: 100%;
}

.el-header {
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 30;
  background: #001733;
  display flex;
  align-items: center;
  padding: 0 20px;

  .act-logo {
    width: 92px;
    height: 25px;
    flex-shrink: 0;
    background: url('./../../assets/img/logo.png') 0 0 no-repeat;
    margin-right: 20px;
  }

  .exam-next-wrap {
    flex: 1;
  }
}

.exam-time-body
  display: inline-block;
  position relative
  width: 80px;
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;

  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  font-weight: 500;
  margin-left: 10px;
  over-flow hidden



.exam-time-bg
  position absolute
  width: 100%
  height 100%
  top 0
  left 0
  background #ddd
  z-index 20


.exam-time
  position relative
  z-index 30
  display flex
  align-items center
  justify-content center

.el-aside {
  padding: 90px 20px 80px 40px!important;
}

.el-container
  height: 100vh;

.el-main {
  padding: 90px 20px 80px 40px!important;
}

.el-footer {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #fafafa;
  display flex;
  align-items: center;
  box-sizing border-box
  padding: 30px 20px!important;

  .footer-pagination {
    flex:1;
  }

  .footer-btn {
    flex-shrink: 0;
  }
}

.footer-pagination .el-pagination.is-background .el-pager .number
  background #ffffff
  border 1px solid #dddddd
  margin 0 !important

.footer-pagination .el-pagination.is-background .el-pager .number.number-answered
  background #e8e8e8

.footer-pagination .el-pagination.is-background .el-pager .number.active
  background #409EFF

.number-flag
  position relative

  &::after {
    content: "\e6fd";
    position: absolute;
    font-family: element-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    vertical-align: baseline;
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #606266;
    top: -26px;
    display: flex;
    justify-content: center;
  }

.end-tips-p
  font-size 15px
  font-weight: bold;
  color #333
  margin: 0 0 15px 0;

.end-tips-sub
  font-size 15px
  color #333
  margin: 0 0 15px 0;
  padding 10px 8px
  background rgb(254, 240, 240)
  border-radius 4px
  color #e42a22

  span
    color #e42a22
    font-weight bold

.info-title
  font-size 18px
  font-weight bold
  color #333
  margin-bottom 20px

.article-title
  font-size 14px
  font-weight bold
  color #333

.article-name
  font-size 13px
  font-weight bold
  color #333
  margin: 8px 0

.article-content
  font-size 14px
  color #333
  line-height 21px

.question-title
  display flex
  font-size 14px
  font-weight bold
  color #333
  line-height 21px
  margin-bottom 20px
  padding-left 9px

  .question-title-key
    font-weight normal

  .question-title-content
    flex 1
    white-space normal
    margin-left 5px
    font-weight normal

.answer-item
  padding-left 5px
  font-size 14px
  color #333
  line-height 21px
  margin-bottom 30px

  .el-radio__inner
    margin-top 4px

  .el-radio__label
    display flex
    font-size 14px
    color #333
    line-height 21px

    .question-item-key
      font-weight normal

    .question-item-content
      flex 1
      white-space normal
      margin-left 5px
      font-weight normal

  .el-radio
    display flex

.question-solution-wrap
  background: #EBEEF5;
  padding: 30px;
  border-radius: 10px;

  .question-solution-title
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
    font-weight bold

  .question-solution-list
    display flex
    margin-bottom: 8px;

    .question-solution-item
      span
        color #409EFF

    .question-solution-item + .question-solution-item
      margin-left 30px

  .question-solution-content
    margin-top 10px

  .question-solution-tips
    margin-top 20px
    color #666

.exam-editor
  color: #333;
  line-height: 21px;
  p+p
    margin-top 20px

  img
    vertical-align middle
    margin 0 5px

  u
    text-decoration none
    border-bottom 1px solid #333
    padding-bottom 3px

  u.upper-line
    text-decoration none
    border-top 1px solid #333
    padding-top 0px
    border-bottom none
    padding-bottom 3px
</style>
