<template>
  <div class="start-body">
    <div class="start-header">
      <div class="act-logo"></div>
    </div>
    <h2 class="start-title">
      {{ examTitle.split(" - ")[0] }} - {{ typeText }}
    </h2>
    <div style="margin-top: 20px" v-if="type == 5">
      <p>ACT考试总时长为3小时5分钟，按以下顺序进行：</p>
      <p>
        The ACT test takes 3 hours and 5 minutes (including breaks) . The
        section order is as follows:
      </p>
      <br />
      <p>English: 45 min</p>
      <p>Mathematics: 60 min</p>
      <p>Break: 10 min</p>
      <p>Reading: 35 min</p>
      <p>Science: 35 min</p>
      <br />
      <p>考生在数学部分可以使用计算器。</p>
      <p>You are permitted to use a calculator on mathematics section.</p>
    </div>
    <div class="start-btn-wrap">
      <span class="start-btn" @click="startExam">Start</span>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { getParams } from "../../assets/js/util";

export default {
  data() {
    return {
      examTitle: "",
      actId: 0,
      userId: 0,
      type: 0,
      isLoading: false
    };
  },
  computed: {
    typeText() {
      const type = this.type;
      let result = "";
      switch (type) {
        case 1:
          result = "English";
          break;
        case 2:
          result = "Math";
          break;
        case 3:
          result = "Reading";
          break;
        case 4:
          result = "Science";
          break;
        case 5:
          result = "All";
          break;
      }
      return result;
    }
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.examTitle = decodeURIComponent(query.title);
    this.userId = query.userId;
    this.actId = query.actId;
    this.type = Number(query.type);
  },
  methods: {
    async startExam() {
      if (this.isLoading) return;
      this.isLoading = true;
      let questionType = this.typeText.toLowerCase();
      let isActAllSubjectTesting = false;
      if (questionType == "all") {
        questionType = "english";
        isActAllSubjectTesting = true;
      }
      try {
        const url = "/startExam";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            actId: this.actId,
            username: this.userId,
            questionType: questionType,
            isActAllSubjectTesting: isActAllSubjectTesting,
            actAllSubjectTestingId: 0
          })
        });
        console.log("result", result, status);

        this.isLoading = false;

        if (status === 0) {
          this.$router.push(
            `/exam?examId=${result.id}&userId=${this.userId}&step=1`
          );
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.start-body
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff

  .start-header
    width 100%
    height 73px
    background #001733
    display flex
    align-items center

    .act-logo
      width 100px
      height 27px
      margin-left 25px
      background url('./../../assets/img/logo.png') 0 0 no-repeat

  .start-title
    display flex
    align-items center
    height 67px
    font-size 22px
    color #333
    border-bottom 3px solid #e42a22
    padding-left 25px

  .start-btn-wrap
    display flex
    align-items center
    justify-content center
    margin-top 40px

    .start-btn
      width: 140px;
      display: flex;
      align-items center
      justify-content center
      cursor: pointer;
      border-color: #e42a22;
      background-image: linear-gradient(to right,#eb7a21 0%,#e42a22 100%);
      box-shadow: none;
      color: #fff;
      text-shadow: none;
      font-weight: bold;
      border-radius: 3px;
      padding: 7px 16px 6px;
      box-sizing: border-box;
</style>
