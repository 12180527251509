<template>
  <div class="act-list-body">
    <div class="act-title">
      <h2>ACT真题</h2>
    </div>

    <div style="margin-top: 20px">
      购买在线机考的完整功能，请登录小程序“树蛙解析”或公众号“ACT谜”，咨询我们课程顾问微信：18310295153。
    </div>

    <main class="act-main">
      <el-table v-loading="loading" :data="actList" border style="width: 100%">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column fixed="right" label="操作" width="600">
          <template slot-scope="scope" v-if="scope.row.disable === 1">
            <el-button
              @click="handleClick(scope.row, 1)"
              type="text"
              size="small"
            >
              ENGLISH </el-button
            ><el-button
              @click="handleClick(scope.row, 2)"
              type="text"
              size="small"
            >
              MATH </el-button
            ><el-button
              @click="handleClick(scope.row, 3)"
              type="text"
              size="small"
            >
              READING </el-button
            ><el-button
              @click="handleClick(scope.row, 4)"
              type="text"
              size="small"
            >
              SCIENCE
            </el-button>
            <el-button
              @click="handleClick(scope.row, 5)"
              type="text"
              size="small"
            >
              COMPOSITE
            </el-button>
            <el-button
                @click="gotoWordsPage(scope.row)"
                type="text"
                size="small"
            >
              词汇
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import qs from "qs";
import { getParams } from "../../assets/js/util";

export default {
  data() {
    return {
      userId: 0,
      actList: [],
      loading: true
    };
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.userId = query.userId || "user";

    this.getActList(0);
    // this.getActTypeList();
  },
  methods: {
    async getActList() {
      try {
        const url = "/getTrailActList";
        const {
          data: { data: actListResult, status }
        } = await this.$http({
          url,
          data: qs.stringify({}),
          method: "POST"
        });
        console.log("result", actListResult);

        if (status === 0) {
          this.actList = actListResult;
          //this.total = actListResult.total;
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    handleClick(row, type) {
      console.log("row", row, type);
      const url = `${location.protocol}//${location.host}/start?userId=trail&actId=${row.id}&type=${type}&title=${encodeURIComponent(row.name)}`;
      window.open(url);
    },
  }
};
</script>

<style lang="stylus" scoped>
.act-list-body {
  width: 1180px;
  min-height 100vh
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}

.act-title {

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.act-main {
  margin-top: 40px;
}

.act-pagination {
  margin-top: 20px;
}
</style>
