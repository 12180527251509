<template>
  <div class="start-body">
    <div class="start-header">
      <div class="act-logo"></div>
    </div>
    <h2 class="start-title">
<!--      {{ examTitle.split(" - ")[0] }} - {{ typeText }}-->
      Break Time
    </h2>
    <div style="margin-top: 20px; text-align: center; font-size: 60px;">
      <p>{{ leftTimeFormat }} to reading section</p>
    </div>
    <div class="start-btn-wrap">
      <span class="start-btn" @click="startExam">End Break</span>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { getParams } from "../../assets/js/util";

export default {
  data() {
    return {
      examTitle: "",
      actId: 0,
      userId: 0,
      type: 0,
      leftTime: 0,
      totalTime: 600,
      isLoading: false
    };
  },
  computed: {
    leftTimeFormat() {
      const { totalTime, leftTime } = this;
      const remainTime = totalTime - leftTime;
      if (remainTime === 0) {
        return "00:00";
      }

      let remainMin = parseInt(remainTime / 60);
      let remainSec = remainTime % 60;

      if (remainMin < 10) {
        remainMin = `0${remainMin}`;
      }

      if (remainSec < 10) {
        remainSec = `0${remainSec}`;
      }
      return `${remainMin}:${remainSec}`;
    },
    typeText() {
      const type = this.type;
      let result = "";
      switch (type) {
        case 1:
          result = "English";
          break;
        case 2:
          result = "Math";
          break;
        case 3:
          result = "Reading";
          break;
        case 4:
          result = "Science";
          break;
        case 5:
          result = "All";
          break;
      }
      return result;
    }
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.userId = query.userId;
    this.actId = query.actId;
    this.actAllSubjectTestingId = query.actAllSubjectTestingId;
    this.type = Number(query.type);

    this.getBreakTime()
  },
  methods: {
    async getBreakTime() {

      try {
        const url = "/getBreakTime";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            actAllSubjectTestingId: this.actAllSubjectTestingId
          })
        });


        this.leftTime = result.leftTime;
        console.log(result.leftTime);
        if (status === 0) {
            this.initLeftTimerInterval();
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    initLeftTimerInterval() {

      if (this.timer) {
        clearInterval(this.timer);
      } else {
        this.timer = setInterval(() => {
          if (this.leftTime < this.totalTime) {
            this.leftTime += 1;
          } else {
            clearInterval(this.timer);
            this.startExam();
          }
        }, 1000);
      }
    },
    async startExam() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const url = "/startExam";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            actId: this.actId,
            username: this.userId,
            questionType: this.typeText.toLowerCase(),
            isActAllSubjectTesting: true,
            actAllSubjectTestingId: this.actAllSubjectTestingId
          })
        });
        console.log("result", result, status);

        this.isLoading = false;

        if (status === 0) {
          this.$router.push(`/exam?examId=${result.id}&userId=${this.userId}&step=2&isPreview=0`);
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.start-body
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff

  .start-header
    width 100%
    height 73px
    background #001733
    display flex
    align-items center

    .act-logo
      width 100px
      height 27px
      margin-left 25px
      background url('./../../assets/img/logo.png') 0 0 no-repeat

  .start-title
    display flex
    align-items center
    height 67px
    font-size 22px
    color #333
    border-bottom 3px solid #e42a22
    padding-left 25px

  .start-btn-wrap
    display flex
    align-items center
    justify-content center
    margin-top 40px

    .start-btn
      width: 140px;
      display: flex;
      align-items center
      justify-content center
      cursor: pointer;
      border-color: #e42a22;
      background-image: linear-gradient(to right,#eb7a21 0%,#e42a22 100%);
      box-shadow: none;
      color: #fff;
      text-shadow: none;
      font-weight: bold;
      border-radius: 3px;
      padding: 7px 16px 6px;
      box-sizing: border-box;
</style>
