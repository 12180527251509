export const getParams = url => {
  const queryStr = url.split("?")[1] || "";
  const queryPairs = queryStr.split("&");
  const ret = {};

  queryPairs.forEach(str => {
    const pair = str.split("=");
    /* eslint-disable prefer-destructuring */
    ret[pair[0]] = pair[1];
  });

  return ret;
};

export const getTimeParam = timeStep => {
  const day = parseInt(timeStep / (24 * 60 * 60), 10);
  let [hours, minute, second] = [0, 0, 0];
  let remain = timeStep - day * 24 * 60 * 60;
  if (remain > 0) {
    hours = parseInt(remain / (60 * 60), 10);
    remain -= hours * 60 * 60;
    if (remain > 0) {
      minute = parseInt(remain / 60, 10);
      second = remain - minute * 60;
    }
  }
  return {
    day,
    hours,
    minute,
    second
  };
};

export const getTimestamp = time => {
  if (typeof time === "string") {
    time = time.replace(/-/g, "/");
    if (String(new Date(time)) === "Invalid Date") {
      return new Date().getTime() / 1000;
    }
    return new Date(time).getTime() / 1000;
  }
  return new Date(time).getTime() / 1000;
};

export const formatDiffDate = (timestamp, type = ".", needTime) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  let result = `${year}${type}${month}${type}${day}`;
  if (needTime) {
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();
    if (h < 10) {
      h = `0${h}`;
    }
    if (m < 10) {
      m = `0${m}`;
    }
    if (s < 10) {
      s = `0${s}`;
    }
    result += ` ${h}:${m}:${s}`;
  }
  return result;
};
