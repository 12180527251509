import WangEditor, { PanelMenu, Panel } from "wangeditor";
import Vue from "vue";

function createKityformula(editor) {
  // panel 中需要用到的id
  const inputIFrameId = "kityformula_" + Math.ceil(Math.random() * 10);
  const btnOkId = "kityformula-btn" + Math.ceil(Math.random() * 10);

  const conf = {
    width: 900,
    height: 560,

    // panel 中可包含多个 tab
    tabs: [
      {
        // tab 的标题
        title: editor.i18next.t("menus.panelMenus.formula.插入公式"),
        // 模板
        tpl: `<div>
                  <iframe id="${inputIFrameId}" class="iframe" height="500px" width="100%" frameborder="0" scrolling="no" src="/kityformula/index.html"></iframe>
                  <div class="w-e-button-container">
                      <button id="${btnOkId}" class="right">
                          ${editor.i18next.t("确认插入")}
                      </button>
                  </div>
              </div>`,
        // 事件绑定
        events: [
          {
            selector: "#" + btnOkId,
            type: "click",
            fn: async () => {
              // 执行插入公式
              const node = document.getElementById(inputIFrameId);
              const kfe = node.contentWindow.kfe;

              let latex = kfe.execCommand("get.source");
              latex = latex.replace(/\s/g, ""); // 去掉空格

              let src = `https://latex.codecogs.com/gif.latex?${latex}`;

              let result = false;
              let base64Src = "";

              try {
                base64Src = await getBase64Image(src);
                result = true;
              } catch (err) {
                result = false;
              }

              if (result) {
                editor.cmd.do(
                  "insertHTML",
                  '<img class="formula" src="' +
                    base64Src +
                    '" data-latex="' +
                    latex +
                    '" />'
                );
              } else {
                let bus = new Vue();
                bus.$message({
                  showClose: true,
                  message: "添加出错, 请重试",
                  type: "error"
                });
              }

              // 使用 editor.cmd.do  无法关闭弹窗
              // editor.cmd.do(
              //   "insertHTML",
              //   '<img class="formula" src="https://latex.codecogs.com/gif.latex?' +
              //     latex +
              //     '" data-latex="' +
              //     latex +
              //     '" />'
              // );

              // editor.txt.append(
              //   '<img class="formula" src="https://latex.codecogs.com/gif.latex?' +
              //     latex +
              //     '" data-latex="' +
              //     latex +
              //     '" />'
              // );

              // 返回 true，表示该事件执行完之后，panel 要关闭。否则 panel 不会关闭
              return result;
            }
          }
        ]
      } // tab end
    ] // tabs end
  };

  return conf;
}

function _getCanvas(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
  var dataURL = canvas.toDataURL("image/" + ext);
  return dataURL;
}

function getBase64Image(src) {
  return new Promise((resolve, reject) => {
    if (src === "") {
      reject(false);
    }
    var image = new Image();
    image.src = src;
    image.setAttribute("crossOrigin", "Anonymous");
    image.onload = function() {
      var base64 = _getCanvas(image);
      resolve(base64);
    };
    image.onerror = function() {
      reject(false);
    };
  });
}

export default class Kityformula extends PanelMenu {
  // 公式输入插件
  constructor(editor) {
    const $elem = WangEditor.$(
      `<div class="w-e-menu" data-title="公式">
            <i class="el-icon-help" style="font-size:18px;"></i>
        </div>`
    );
    super($elem, editor);
  }

  // 菜单点击事件
  clickHandler() {
    // 做任何你想做的事情s
    // 可参考【常用 API】文档，来操作编辑器
    const conf = createKityformula(this.editor);
    const panel = new Panel(this, conf);
    panel.create();
  }

  tryChangeActive() {}
}
