<template>
  <el-dialog title="编辑" width="80%" :visible.sync="dialogFormVisible">
    <vueEditor v-model="content"></vueEditor>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelBtn">取 消</el-button>
      <el-button type="primary" @click="sureBtn">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: String
  },
  data() {
    return {
      content: "",
      dialogFormVisible: false
    };
  },
  components: {},
  watch: {
    value(newVal) {
      this.content = newVal || "";
    },
    dialogFormVisible(newVal) {
      if (!newVal) {
        this.content = "";
        this.$emit("input", "");
      }
    }
  },
  methods: {
    cancelBtn() {
      this.dialogFormVisible = false;
    },
    sureBtn() {
      this.$emit("sure", this.content);
      this.$emit("input", this.content);
      this.dialogFormVisible = false;
    },
    show() {
      this.dialogFormVisible = true;
    }
  }
};
</script>

<style lang="stylus">
.w-e-text
  color: #333;
  p
    color: #333;

  p+p
    margin-top 20px

  img
    vertical-align middle
    margin 0 5px

  u
    text-decoration none
    border-bottom 1px solid #333
    padding-bottom 3px

  u.upper-line
    text-decoration none
    border-top 1px solid #333
    padding-top 0px
    border-bottom none
    padding-bottom 3px
</style>
