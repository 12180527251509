<template>
  <el-dialog
    :title="title"
    width="530px"
    :visible.sync="dialogFormVisible"
    center
  >
    <el-row v-for="(item, key) in list" :key="key" class="row">
      <p v-if="item.compositeScore != null">
        <span>{{ getLocalTime(item.created_datetime) }}</span>
        <b>{{ item.compositeScore }}</b>
        <span
          style="float:right;color:#4d90fe;cursor:pointer"
          @click="gotoActAllSubjectTestingResult(item)"
          >查看</span
        >
      </p>
      <p v-else>
        <span>
          {{ getLocalTime(item.createdDatetime) }}
        </span>
        <b>
          {{ item.score }}
        </b>
        <span>
          {{ item.rightQuestions }}/<span>{{
            type == 1 ? "75" : type == 4 || type == 3 ? "40" : "60"
          }}</span>
        </span>
        <span
          style="float:right;color:#4d90fe;cursor:pointer"
          @click="gotoLogs(item.id)"
          >查看</span
        >
      </p>

      <!-- <el-col :span="3"> </el-col> -->
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="again" type="primary">再做一次</el-button>
    </div>
  </el-dialog>
</template>
<script>
import qs from "qs";
import { getParams } from "../assets/js/util";

export default {
  data() {
    return {
      dialogFormVisible: false,
      row: null,
      type: null,
      title: null,
      list: []
    };
  },
  methods: {
    gotoActAllSubjectTestingResult(row) {
      console.log("row", row);
      this.$router.push({
        path: "/actAllSubjectTestingResult",
        query: {
          examId: 0,
          userId: this.userId,
          actAllSubjectTestingId: row.ids
        }
      });
    },
    gotoLogs(id) {
      this.$router.push({
        path: "/result",
        query: {
          examId: id,
          userId: this.userId
        }
      });
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    getLocalTime(shijianchuo) {
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + "-" + this.add0(m) + "-" + this.add0(d);
    },
    async getActList(row) {
      try {
        const url = "/getExamListByActId";
        const {
          data: { data: data, status }
        } = await this.$http({
          url,
          data: qs.stringify({
            userId: row.userId,
            actId: row.actId,
            type: row.type
          }),
          method: "POST"
        });

        console.log;
        if (status === 0) {
          let list = [];
          this.list = data;
          console.log(data, 11111);
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    open(data, type) {
      this.dialogFormVisible = true;
      this.row = data;
      this.type = type;
      this.title = data.type.charAt(0).toUpperCase() + data.type.slice(1);
      this.getActList(this.row);
    },
    close() {
      this.dialogFormVisible = false;
    },
    again() {
      this.$parent.handleClick(this.row, this.type);
    }
  },
  created() {
    const query = getParams(window.location.href);
    this.userId = query.userId;
  }
};
</script>
<style scoped lang="stylus">
.row {
  font-size: 16px;
  b{
    margin-left: 15px;
    margin-right 15px

  }
}
</style>
