import Vue from "vue";
import vueEditor from "vue-wangeditor-awesome";
import COS from "cos-js-sdk-v5";
import md5 from "md5";
import Kityformula from "./components/kityformula";
import MyscriptMathWeb from "./components/myscript-math-web";
import UpperLine from "./components/upperLine";
import Superscript from "./components/supscript";
import Subscript from "./components/subscript";
import Clear from "./components/clear";

Vue.use(vueEditor, {
  extendedMenus: {
    kityformulaKey: Kityformula,
    myscriptMathWeb: MyscriptMathWeb,
    upperLine: UpperLine,
    superscript: Superscript,
    subscript: Subscript,
    clear: Clear
  },
  directiveName: "vueEditor",
  height: 400,
  showMenuTooltips: true,
  zIndex: 500,
  menus: [
    "head",
    "bold",
    "italic",
    "underline",
    "upperLine",
    "superscript",
    "subscript",
    "indent",
    "foreColor",
    "backColor",
    "justify",
    "image",
    "undo",
    "redo",
    "kityformulaKey",
    "myscriptMathWeb",
    "clear"
  ],
  colors: ["#333", "#ff0", "#fff"],
  uploadImgMaxLength: 1,
  customUploadImg: function(resultFiles, insertImgFn) {
    // resultFiles 是 input 中选中的文件列表
    // insertImgFn 是获取图片 url 后，插入到编辑器的方法

    // 上传图片，返回结果，将图片插入到编辑器中
    // insertImgFn(imgUrl)

    console.log(resultFiles);

    const filename = md5(`${resultFiles[0].name}-${new Date().getTime()}`);
    const selectedFile = resultFiles[0];

    let cos = new COS({
      SecretId: "AKIDQq42sh8uQkqiQ962mY5MNWTCOMMhrjMJ",
      SecretKey: "nOjodCsLZYJnBwdXxxRXNzgNTPoPmzQ2"
    });
    cos.putObject(
      {
        Bucket: "ame-1253155612",
        Region: "ap-beijing",
        Key: filename,
        StorageClass: "STANDARD",
        Body: selectedFile, // 上传文件对象
        onProgress: function(progressData) {
          console.log(JSON.stringify(progressData));
        }
      },
      function(err, data) {
        if (!err) {
          console.log(`${window.location.protocol}//${data.Location}`);
          insertImgFn(`${window.location.protocol}//${data.Location}`);
        } else {
          insertImgFn("");
        }
      }
    );
  }
});
