import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Index.vue";
import Trial from "../views/Trial/Index.vue";
import Fragment from "../views/Fragment/Index.vue";
import Exam from "../views/Exam/Index.vue";
import Preview from "../views/Preview/Index.vue";
import Result from "../views/Result/Index.vue";
import ActAllSubjectTestingResult from "../views/ActAllSubjectTestingResult/Index.vue";
import Start from "../views/Start/Index.vue";
import Break from "../views/Break/Index.vue";
import History from "../views/History/Index.vue";
import SearchList from "../views/SearchList/Index.vue";
import Word from "../views/Vocabulary/Index.vue";
import EditQuestion from "../views/CMS/EditQuestion.vue";
import SyncQuestion from "../views/CMS/SyncQuestion.vue";
import ExportQuestion from "../views/CMS/ExportQuestion.vue";
import ReadArticle from "../views/ReadArticle/Index.vue";
import ReadDetail from "../views/ReadArticle/ReadDetail.vue";
import ContEdit from "../views/ReadArticle/ContEdit.vue";
import QuesEdit from "../views/ReadArticle/QuesEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/fragment",
    name: "Fragment",
    component: Fragment
  },
  {
    path: "/trial",
    name: "Trial",
    component: Trial
  },
  {
    path: "/exam",
    name: "Exam",
    component: Exam
  },
  {
    path: "/result",
    name: "Result",
    component: Result
  },
  {
    path: "/actAllSubjectTestingResult",
    name: "ActAllSubjectTestingResult",
    component: ActAllSubjectTestingResult
  },
  {
    path: "/start",
    name: "Start",
    component: Start
  },
  {
    path: "/history",
    name: "History",
    component: History
  },
  {
    path: "/search",
    name: "SearchList",
    component: SearchList
  },
  {
    path: "/break",
    name: "Break",
    component: Break
  },
  {
    path: "/vocabulary",
    name: "Vocabulary",
    component: Word
  },
  {
    path: "/cms/preview",
    name: "Preview",
    component: Preview
  },
  {
    path: "/cms/editQuestion",
    name: "EditQuestion",
    component: EditQuestion
  },
  {
    path: "/cms/syncQuestion",
    name: "SyncQuestion",
    component: SyncQuestion
  },
  {
    path: "/cms/exportQuestion",
    name: "ExportQuestion",
    component: ExportQuestion
  },
  {
    path: "/readArticle",
    name: "ReadArticle",
    component: ReadArticle
  },
  {
    path: "/readArticle/readDetail",
    name: "ReadDetail",
    component: ReadDetail
  },
  {
    path: "/readArticle/contEdit",
    name: "ContEdit",
    component: ContEdit
  },
  {
    path: "/readArticle/quesEdit",
    name: "QuesEdit",
    component: QuesEdit
  }
  // {
  // path: "/about",
  // name: "About",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () =>
  // import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
