import E from "wangeditor";
const { $, BtnMenu } = E;

class SuperScriptMenu extends BtnMenu {
  constructor(editor) {
    const $elem = $(
      `<div class="w-e-menu" data-title="上角标">
          <i class="w-e-icon-fullscreen" style="transform: rotateZ(180deg);"></i>        
      </div>`
    );
    super($elem, editor);
  }
  // 菜单点击事件
  clickHandler() {
    const editor = this.editor;
    const isSelectEmpty = editor.selection.isSelectionEmpty();
    const text = editor.selection.getSelectionText();

    if (isSelectEmpty) {
      // 选区范围是空的，插入并选中一个“空白”
      editor.selection.createEmptyRange();
    }

    // 执行 Underline 命令
    editor.cmd.do("insertHTML", `<sup>${text}</sup>`);

    if (isSelectEmpty) {
      // 需要将选区范围折叠起来
      editor.selection.collapseRange();
      editor.selection.restoreSelection();
    }
  }
  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive() {}
}

export default SuperScriptMenu;
