<template>
  <div class="detail-body" v-loading="loading">
    <div
      class="header"
      style="height: 64px; line-height: 64px; position:fixed; top: 0"
    >
      <p>
        <label> 阅读时间：<span ref="startTimer"></span> </label>
      </p>
    </div>
    <div class="content">
      <h2>{{ detailData.name }}</h2>
      <div v-html="detailData.content" class="content-box"></div>
      <div style="margin-bottom: 32px">
        <el-button type="primary" plain @click="readEnd">读完全文</el-button>
        <p v-show="textShow" style="margin-top: 16px">
          本文的阅读速度：<span style="color: #409EFF;font-size: 20px"
            >{{ speed }}
          </span>
          words/minute
        </p>
      </div>
      <div
        v-for="(item, index) in questionList"
        :key="item.id"
        class="ques-cont"
      >
        <div>
          <span class="index">{{ index + 1 }}.</span>
          <div v-html="item.content" class="cont"></div>
        </div>
        <div class="answer-cont">
          <div
            v-if="item.itemA"
            class="answer-item"
            @click="answerClick('A', item)"
          >
            A: {{ item.itemA }}
            <i class="el-icon-check" v-show="item.isWrang == 'A'"></i>
            <i class="el-icon-close" v-show="item.isWrang == 'AN'"></i>
          </div>
          <div
            v-if="item.itemB"
            class="answer-item"
            @click="answerClick('B', item)"
          >
            B: {{ item.itemB }}
            <i class="el-icon-check" v-show="item.isWrang == 'B'"></i>
            <i class="el-icon-close" v-show="item.isWrang == 'BN'"></i>
          </div>
          <div
            v-if="item.itemC"
            class="answer-item"
            @click="answerClick('C', item)"
          >
            C: {{ item.itemC }}
            <i class="el-icon-check" v-show="item.isWrang == 'C'"></i>
            <i class="el-icon-close" v-show="item.isWrang == 'CN'"></i>
          </div>
          <div
            v-if="item.itemD"
            class="answer-item"
            @click="answerClick('D', item)"
          >
            D: {{ item.itemD }}
            <i class="el-icon-check" v-show="item.isWrang == 'D'"></i>
            <i class="el-icon-close" v-show="item.isWrang == 'DN'"></i>
          </div>
        </div>
      </div>
      <div class="introduce">*{{ detailData.introduce }}</div>
    </div>
  </div>
</template>

<script>
import { getParams } from "../../assets/js/util";
export default {
  data() {
    return {
      detailData: [],
      questionList: [],
      id: "",
      loading: false,
      hour: 0,
      minutes: 0,
      seconds: 0,
      timer: null,
      textShow: false,
      speed: 0,
      words: 0
    };
  },
  methods: {
    async getDetail() {
      this.loading = true;
      try {
        const url = "/read/startExam";
        const {
          data: { data: detailData, status }
        } = await this.$http({
          url,
          params: { id: this.id },
          method: "GET"
        });

        if (status === 0) {
          this.detailData = detailData;
          this.questionList = detailData.questionList;
          this.words = detailData.words;
          this.timer = setInterval(this.startTimer, 1000);
        } else {
          this.$message.error("获取问题失败");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    answerClick(answer, item) {
      if (item.answer && answer === item.answer.toUpperCase()) {
        this.$set(item, "isWrang", answer);
      } else {
        this.$set(item, "isWrang", `${answer}N`);
      }
    },
    startTimer() {
      this.seconds += 1;
      if (this.seconds === 60 && this.minutes !== 59) {
        this.minutes += 1;
        this.seconds = 0;
      } else if (this.seconds === 60 && this.minutes === 59) {
        this.hour += 1;
        this.minutes = 0;
        this.seconds = 0;
      }
      this.renderTime();
    },
    renderTime() {
      this.$refs.startTimer.innerHTML =
        (this.hour < 10 ? "0" + this.hour : this.hour) +
        ":" +
        (this.minutes < 10 ? "0" + this.minutes : this.minutes) +
        ":" +
        (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },
    readEnd() {
      this.textShow = true;
      let time = 1;
      time = this.hour ? this.hour * 60 + this.minutes : this.minutes;
      if (time === 0) {
        time = 1;
      }
      this.speed = Math.floor(this.words / time);
    }
  },
  created() {
    const query = getParams(window.location.href);
    this.id = query.id;
    this.getDetail();
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="stylus" scoped>
.detail-body{
  width 940px;
  min-height 100vh;
  margin 0 auto;
  background #fff;
}
.header p{
  width 940px;
  height 64px;
  margin 0 auto;
  float: right;
  background: #fff
}
.header p label{
  display: inline-block;
  float: right
}
.header p span{
  display: inline-block;
  width: 80px;
  color: #f56c6c
}
.detail-body .content{
  padding: 0 100px;
  margin-top: 64px
}
.content h2{
  padding: 32px 64px 64px 64px;
  font-size: 24px;
  text-align: center;
}
.introduce{
  padding: 76px 0;
  color: #606266
}
.content-box{
  margin-bottom: 32px;
  line-height: 24px
}
.ques-cont{
  position: relative;
  margin-bottom: 32px;
  line-height: 24px
}
.ques-cont .index{
  position: absolute;
  top: 0;
  left: 0;
}
.ques-cont .cont {
  padding-left: 32px
}
.answer-item{
  margin-top: 16px;
}
/deep/.el-icon-check{
  color: green;
  font-size: 18px;
  font-weight: 700
}
/deep/.el-icon-close{
  color: red;
  font-size: 16px;
  font-weight: 700
}
</style>
