<template>
  <div class="result-body">
    <div class="result-header">
      <div class="act-logo"></div>
      <div class="backFont">返回主页</div>
      <div class="backTo" @click="backHistory"></div>
    </div>
    <h2 class="result-title">Result</h2>
    <div class="result-main">
      <el-row>
        <el-col :span="24">
          <p class="exam-title">{{ actName }}</p>
        </el-col>
      </el-row>
      <!--      <el-row>-->
      <!--        <el-col :span="8">-->
      <!--          <p class="exam-table-label">Number of questions</p>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <!--      <el-row>-->
      <!--        <el-col :span="8">-->
      <!--          <p class="exam-table-label">Duration</p>-->
      <!--        </el-col>-->
      <!--        <el-col :span="8">-->
      <!--          <p class="exam-table-value" v-if="duration">-->
      <!--            {{ duration | formatDuration }}-->
      <!--          </p>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <el-divider></el-divider>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Date</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value" v-if="startTime">
            {{ startTime | getTimestamp }}
          </p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row id="scorelist" style="display:block;margin: 10px">
        <el-col :span="8">
          <p class="composite-score">{{ compositeScore }}</p>
          <p class="composite-font">Composite</p>
        </el-col>
        <el-col :span="4">
          <p class="subject-score">{{ englishScore }}</p>
          <p class="subject-font">English</p>
          <p class="subject-font">{{ englishRightQuestions }} / 75</p>
        </el-col>
        <el-col :span="4">
          <p class="subject-score">{{ mathScore }}</p>
          <p class="subject-font">Math</p>
          <p class="subject-font">{{ mathRightQuestions }} / 60</p>
        </el-col>
        <el-col :span="4">
          <p class="subject-score">{{ readingScore }}</p>
          <p class="subject-font">Reading</p>
          <p class="subject-font">{{ readingRightQuestions }} / 40</p>
        </el-col>
        <el-col :span="4">
          <p class="subject-score">{{ scienceScore }}</p>
          <p class="subject-font">Science</p>
          <p class="subject-font">{{ scienceRightQuestions }} / 40</p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="24">
          <p class="exam-result-title">Detail report of each section</p>
        </el-col>
      </el-row>

      <div class="subject_result">
        <ul class="subject_tab">
          <li
            v-on:click="changeTab('1')"
            v-bind:class="{ active: isActive === '1' }"
          >
            English
          </li>
          <li
            v-on:click="changeTab('2', mathExamId)"
            v-bind:class="{ active: isActive === '2' }"
          >
            Math
          </li>
          <li
            v-on:click="changeTab('3', readingExamId)"
            v-bind:class="{ active: isActive === '3' }"
          >
            Reading
          </li>
          <li
            v-on:click="changeTab('4', scienceExamId)"
            v-bind:class="{ active: isActive === '4' }"
          >
            Science
          </li>
        </ul>

        <ul class="result_list">
          <li v-if="isActive === '1'">
            <el-row>
              <el-col :span="8">
                <p class="exam-table-label">Duration</p>
              </el-col>
              <el-col :span="8">
                <p class="exam-table-value" v-if="englishDuration">
                  {{ englishDuration | formatDuration }}
                </p>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <p class="exam-title">Answer Listing</p>
              </el-col>
            </el-row>
            <el-table
              v-loading="loading"
              stripe
              :data="englishAnswerList"
              border
              style="width: 100%"
            >
              <el-table-column align="center" width="150" label="Item Number">
                <template slot-scope="scope"
                  ><p>Question {{ scope.row.number }}</p></template
                >
              </el-table-column>
              <el-table-column
                align="center"
                width="100"
                prop="articleTitle"
                label="Passage"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="testing"
                label="考点/归类"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="answer"
                label="Answer"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="userAnswer"
                label="Your Answer"
              ></el-table-column>
              <el-table-column align="center" width="120" label="Result">
                <template slot-scope="scope">
                  <p v-if="scope.row.userAnswer === ''" style="color: #606266;">
                    Unanswered
                  </p>
                  <p
                    v-else-if="scope.row.userAnswer === scope.row.answer"
                    style="color:#67C23A;"
                  >
                    Right
                  </p>
                  <p v-else style="color:#F56C6C;">Wrong</p>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                  >
                    Answer Analysis
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </li>
          <li v-else-if="isActive === '2'">
            <el-row>
              <el-col :span="8">
                <p class="exam-table-label">Duration</p>
              </el-col>
              <el-col :span="8">
                <p class="exam-table-value" v-if="mathDuration">
                  {{ mathDuration | formatDuration }}
                </p>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <p class="exam-title">Answer Listing</p>
              </el-col>
            </el-row>
            <el-table
              v-loading="loading"
              stripe
              :data="mathAnswerList"
              border
              style="width: 100%"
            >
              <el-table-column align="center" width="150" label="Item Number">
                <template slot-scope="scope"
                  ><p>Question {{ scope.row.number }}</p></template
                >
              </el-table-column>
              <el-table-column
                align="center"
                width="100"
                prop="articleTitle"
                label="Passage"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="testing"
                label="考点/归类"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="answer"
                label="Answer"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="userAnswer"
                label="Your Answer"
              ></el-table-column>
              <el-table-column align="center" width="120" label="Result">
                <template slot-scope="scope">
                  <p v-if="scope.row.userAnswer === ''" style="color: #606266;">
                    Unanswered
                  </p>
                  <p
                    v-else-if="scope.row.userAnswer === scope.row.answer"
                    style="color:#67C23A;"
                  >
                    Right
                  </p>
                  <p v-else style="color:#F56C6C;">Wrong</p>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                  >
                    Answer Analysis
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </li>
          <li v-else-if="isActive === '3'">
            <el-row>
              <el-col :span="8">
                <p class="exam-table-label">Duration</p>
              </el-col>
              <el-col :span="8">
                <p class="exam-table-value" v-if="readingDuration">
                  {{ readingDuration | formatDuration }}
                </p>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <p class="exam-title">Answer Listing</p>
              </el-col>
            </el-row>
            <el-table
              v-loading="loading"
              stripe
              :data="readingAnswerList"
              border
              style="width: 100%"
            >
              <el-table-column align="center" width="150" label="Item Number">
                <template slot-scope="scope"
                  ><p>Question {{ scope.row.number }}</p></template
                >
              </el-table-column>
              <el-table-column
                align="center"
                width="100"
                prop="articleTitle"
                label="Passage"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="testing"
                label="考点/归类"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="answer"
                label="Answer"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="userAnswer"
                label="Your Answer"
              ></el-table-column>
              <el-table-column align="center" width="120" label="Result">
                <template slot-scope="scope">
                  <p v-if="scope.row.userAnswer === ''" style="color: #606266;">
                    Unanswered
                  </p>
                  <p
                    v-else-if="scope.row.userAnswer === scope.row.answer"
                    style="color:#67C23A;"
                  >
                    Right
                  </p>
                  <p v-else style="color:#F56C6C;">Wrong</p>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                  >
                    Answer Analysis
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </li>
          <li v-else-if="isActive === '4'">
            <el-row>
              <el-col :span="8">
                <p class="exam-table-label">Duration</p>
              </el-col>
              <el-col :span="8">
                <p class="exam-table-value" v-if="scienceDuration">
                  {{ scienceDuration | formatDuration }}
                </p>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <p class="exam-title">Answer Listing</p>
              </el-col>
            </el-row>
            <el-table
              v-loading="loading"
              stripe
              :data="scienceAnswerList"
              border
              style="width: 100%"
            >
              <el-table-column align="center" width="150" label="Item Number">
                <template slot-scope="scope"
                  ><p>Question {{ scope.row.number }}</p></template
                >
              </el-table-column>
              <el-table-column
                align="center"
                width="100"
                prop="articleTitle"
                label="Passage"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="testing"
                label="考点/归类"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="answer"
                label="Answer"
              ></el-table-column>
              <el-table-column
                align="center"
                width="120"
                prop="userAnswer"
                label="Your Answer"
              ></el-table-column>
              <el-table-column align="center" width="120" label="Result">
                <template slot-scope="scope">
                  <p v-if="scope.row.userAnswer === ''" style="color: #606266;">
                    Unanswered
                  </p>
                  <p
                    v-else-if="scope.row.userAnswer === scope.row.answer"
                    style="color:#67C23A;"
                  >
                    Right
                  </p>
                  <p v-else style="color:#F56C6C;">Wrong</p>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120" label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                  >
                    Answer Analysis
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { getParams, formatDiffDate } from "../../assets/js/util";

export default {
  data() {
    return {
      examId: "",
      actAllSubjectTestingId: "",
      actName: "",
      startTime: "",
      compositeScore: "",
      englishScore: "",
      mathScore: "",
      readingScore: "",
      scienceScore: "",
      englishRightQuestions: "",
      mathRightQuestions: "",
      readingRightQuestions: "",
      scienceRightQuestions: "",
      englishDuration: "",
      mathDuration: "",
      readingDuration: "",
      scienceDuration: "",
      englishAnswerList: [],
      mathAnswerList: [],
      readingAnswerList: [],
      scienceAnswerList: [],
      englishExamId: "",
      mathExamId: "",
      readingExamId: "",
      scienceExamId: "",
      loading: true,
      isActive: "1",
      userId: ""
    };
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.actAllSubjectTestingId = query.actAllSubjectTestingId;
    this.examId = query.examId;
    this.userId = query.userId;

    this.getResultList();
  },
  filters: {
    formatDuration(duration) {
      const minute = parseInt(duration / 60);
      const second = duration % 60;
      return `${minute > 10 ? minute : `0${minute}`}:${
        second > 10 ? second : `0${second}`
      }`;
    },
    getTimestamp(time) {
      return formatDiffDate(new Date(time).getTime() / 1000, "-", true);
    }
  },
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    changeTab: function(num, examId) {
      this.isActive = num;
      this.examId = examId;
    },
    handleClick(row) {
      console.log(row);
      const url = `${location.protocol}//${location.host}/exam?examId=${this.examId}&userId=${this.userId}&step=3&questionId=${row.id}&isPreview=1`;
      window.open(url);
    },
    async getResultList() {
      try {
        const url = "getActAllSubjectTestingResult";
        const {
          data: { data: result, status }
        } = await this.$http({
          url,
          data: qs.stringify({
            actAllSubjectTestingId: this.actAllSubjectTestingId,
            examId: this.examId
          }),
          method: "POST"
        });
        console.log("getResult", result);

        if (status === 0) {
          this.actName = result.actName;
          this.startTime = result.startTime;
          this.compositeScore = result.compositeScore;
          this.englishScore = result.englishScore;
          this.mathScore = result.mathScore;
          this.readingScore = result.readingScore;
          this.scienceScore = result.scienceScore;
          this.englishRightQuestions = result.englishRightQuestions;
          this.mathRightQuestions = result.mathRightQuestions;
          this.readingRightQuestions = result.readingRightQuestions;
          this.scienceRightQuestions = result.scienceRightQuestions;
          this.englishDuration = result.englishDuration;
          this.mathDuration = result.mathDuration;
          this.readingDuration = result.readingDuration;
          this.scienceDuration = result.scienceDuration;
          this.englishAnswerList = result.englishAnswerList;
          this.mathAnswerList = result.mathAnswerList;
          this.readingAnswerList = result.readingAnswerList;
          this.scienceAnswerList = result.scienceAnswerList;
          this.examId = result.englishExamId;
          this.englishExamId = result.englishExamId;
          this.mathExamId = result.mathExamId;
          this.readingExamId = result.readingExamId;
          this.scienceExamId = result.scienceExamId;
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.el-row
  margin-bottom: 10px;

  &:last-child
    margin-bottom: 0;

.result-body
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff

.result-header
  width 100%
  height 73px
  background #001733
  display flex
  align-items center

.act-logo
  width 100px
  height 27px
  margin-left 25px
  background url('./../../assets/img/logo.png') 0 0 no-repeat

.backTo
  cursor pointer
  width 900px
  height: 30px;
  background url('./../../assets/img/back.png')  0 0 no-repeat
  background-size 30px 25px
  position: absolute;
  left: 72%;

.backFont
  color #B1B1B1
  height: 25px;
  position: absolute;
  left: 68%;
  font-weight: bold

.result-title
  display flex
  align-items center
  height 67px
  font-size 22px
  color #333
  border-bottom 3px solid #e42a22
  padding-left 25px

.result-main
  padding 40px 30px 20px

.exam-title
  color #333
  font-size 30px
  font-weight bold

.exam-table-label
  font-size 16px
  color #333
  font-weight bold

.exam-table-value
  font-size 16px
  color #333
  font-weight normal

.exam-result-title
  color #333
  font-size 20px
  font-weight bold

.composite-score {
  width: 140px;
  height: 140px;
  background: #409eff;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
  text-align: center;
  line-height: 140px;
  font-size: 60px;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.composite-font {
  margin-top: 15px
  font-size: 20px;
  text-align: center;
}

.subject-score {
  width: 70px;
  height: 70px;
  background: #409eff;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
  text-align: center;
  line-height: 70px;
  font-size: 25px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 34px;
}

.subject-font {
  margin-top: 15px
  font-size: 15px;
  text-align: center;
}

ul{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}
.subject_tab {
  overflow: hidden;
}
.subject_tab li {
  float: left;
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: .3s;
}
.subject_tab li:not(:first-child) {
  border-left: none;
}
.subject_tab li.active
{
  background-color: red;
  border-color: red
  color: #fff;
  cursor: auto;
}
.result_list{
  overflow: hidden;
  margin-top: -1px;
}
.result_list li {
  padding: 20px;
  border: 1px solid #ccc;
}
</style>
