<template>
  <div class="edit-question-body">
    <div class="edit-question-title">
      <h2>编辑题目</h2>
      <p></p>
    </div>
    <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="ACT：" prop="actId">
        <el-select v-model="form.actId" placeholder="请选择ACT" filterable   value-key="title">
          <el-option
            v-for="item in actList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型：" prop="actType">
        <el-select v-model="form.actType" placeholder="请选择ACT类型">
          <el-option label="ENGLISH" value="ENGLISH"></el-option>
          <el-option label="MATH" value="MATH"></el-option>
          <el-option label="READING" value="READING"></el-option>
          <el-option label="SCIENCE" value="SCIENCE"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题ID：" prop="questionNumber">
        <el-input
          v-model="form.questionNumber"
          type="Number"
          placeholder="请输入问题ID"
          :min="0"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getQuestionByNumber" :loading="getLoading">获取问题内容</el-button>
        <el-button type="success" @click="saveArticle" :loading="saveLoading">保存问题内容</el-button>
        <el-button type="success" @click="saveAndPreviewArticle" :loading="saveLoading">保存并预览问题内容</el-button>
      </el-form-item>
    </el-form>
    <el-form
      ref="articleForm"
      :rules="articleFormRules"
      :model="articleForm"
      label-width="100px"
    >
      <el-form-item label="Passage：">
        <el-input v-model="articleForm.articleTitle"></el-input>
      </el-form-item>
      <el-form-item label="文章题目：">
        <el-input v-model="articleForm.articleName"></el-input>
      </el-form-item>
      <!-- 文稿编辑区域 -->
      <el-form-item label="文章内容：">
        <!-- <vueEditor v-model="articleForm.articleContent"></vueEditor> -->
        <p v-html="articleForm.articleContent || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('articleContent')"
          >编辑文章内容</el-button
        >
      </el-form-item>
      <!-- <el-form-item label="问题标题：">
        <el-input v-model="articleForm.questionTitle"></el-input>
      </el-form-item>
      <el-form-item label="问题名称：">
        <el-input v-model="articleForm.questionName"></el-input>
      </el-form-item> -->
      <el-form-item label="问题内容：">
        <!-- <vueEditor v-model="articleForm.questionContent"></vueEditor> -->
        <p v-html="articleForm.questionContent || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('questionContent')"
          >编辑问题内容</el-button
        >
      </el-form-item>
      <el-form-item label="A选项：">
        <!-- <vueEditor v-model="articleForm.itemA"></vueEditor> -->
        <p v-html="articleForm.itemA || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('itemA')"
          >编辑A选项</el-button
        >
      </el-form-item>
      <el-form-item label="B选项：">
        <!-- <vueEditor v-model="articleForm.itemB"></vueEditor> -->
        <p v-html="articleForm.itemB || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('itemB')"
          >编辑B选项</el-button
        >
      </el-form-item>
      <el-form-item label="C选项：">
        <!-- <vueEditor v-model="articleForm.itemC"></vueEditor> -->
        <p v-html="articleForm.itemC || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('itemC')"
          >编辑C选项</el-button
        >
      </el-form-item>
      <el-form-item label="D选项：">
        <!-- <vueEditor v-model="articleForm.itemD"></vueEditor> -->
        <p v-html="articleForm.itemD || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('itemD')"
          >编辑D选项</el-button
        >
      </el-form-item>
      <el-form-item label="E选项：">
        <!-- <vueEditor v-model="articleForm.itemE"></vueEditor> -->
        <p v-html="articleForm.itemE || '暂无内容'"></p>
        <el-button type="primary" @click="editArticleItem('itemE')"
          >编辑E选项</el-button
        >
      </el-form-item>
      <el-form-item label="正确答案：">
        <p v-html="articleForm.answer"></p>
      </el-form-item>

      <el-form-item label="问题讲解：">
        <p v-html="articleForm.solution || '暂无内容'"></p>
      </el-form-item>
    </el-form>

    <editor-component
      ref="editorEle"
      v-model="propContent"
      @sure="handleSure"
    ></editor-component>
  </div>
</template>

<script>
import qs from "qs";

import EditorComponent from "../../components/EditorComponent";
import {getParams} from "@/assets/js/util";

export default {
  data() {
    return {
      propContent: "",
      propKey: "",
      getLoading: false,
      saveLoading: false,
      form: {
        actId: "",
        actType: "",
        questionNumber: ""
      },
      formRules: {
        actId: [{ required: true, message: "请选择ACT", trigger: "change" }],
        actType: [{ required: true, message: "请选择TYPE", trigger: "change" }],
        questionNumber: [
          { required: true, message: "请输入问题ID", trigger: "blur" }
        ]
      },
      articleForm: {
        articleTitle: "",
        articleName: "",
        articleContent: "",
        questionTitle: "",
        questionName: "",
        questionContent: "",
        itemA: "",
        itemB: "",
        itemC: "",
        itemD: "",
        itemE: "",
        answer: "",
        solution: ""
      },
      articleFormRules: {
        articleTitle: [
          { required: true, message: "请输入Passage", trigger: "blur" }
        ],
        articleName: [
          { required: true, message: "请输入文章题目", trigger: "blur" }
        ],
        articleContent: [
          { required: true, message: "请输入文章内容", trigger: "blur" }
        ],
        questionTitle: [
          { required: true, message: "请输入问题标题", trigger: "blur" }
        ],
        questionName: [
          { required: true, message: "请输入问题名称", trigger: "blur" }
        ],
        questionContent: [
          { required: true, message: "请输入问题内容", trigger: "blur" }
        ],
        itemA: [{ required: true, message: "请输入A选项", trigger: "blur" }],
        itemB: [{ required: true, message: "请输入B选项", trigger: "blur" }],
        itemC: [{ required: true, message: "请输入C选项", trigger: "blur" }],
        itemD: [{ required: true, message: "请输入D选项", trigger: "blur" }],
        itemE: [{ required: false, message: "请输入E选项", trigger: "blur" }],
        answer: [
          { required: true, message: "请输入正确答案", trigger: "blur" }
        ],
        solution: [
          { required: true, message: "请输入问题讲解", trigger: "blur" }
        ]
      },
      actList: []
    };
  },
  components: { EditorComponent },
  created() {
    this.getActList();
    const query = getParams(window.location.href);
    if(query.actId){
      this.form.actId = Number(query.actId)
      this.form.actType = query.type.toUpperCase()
      this.form.questionNumber = query.number
    }
  },
  mounted(){
    if( this.form.actId){
      this.getQuestionByNumber()
    }
  },
  methods: {
    selectBlur(e){
    //  this.saveWardForm.wardCode = e.target.value
    },
    editArticleItem(key) {
      this.propKey = key;
      this.propContent = this.articleForm[key];
      this.$refs.editorEle.show();
    },
    handleSure(content) {
      this.articleForm = {
        ...this.articleForm,
        ...{
          [this.propKey]: content
        }
      };
      this.propKey = "";
      this.propContent = "";
    },
    async getActList() {
      try {
        const url = "/getAllActList";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: {}
        });

        if (status === 0) {
          this.actList = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getQuestionByNumber() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getQuestionByNumberReq();
        } else {
          return false;
        }
      });
    },
    async getQuestionByNumberReq() {
      this.getLoading = true;

      try {
        const url = "/getQuestionByNumber";
        const {
          data: { data: result, status }
        } = await this.$http({
          url,
          data: qs.stringify({
            actId: this.form.actId,
            type: this.form.actType.toLowerCase(),
            questionNumber: this.form.questionNumber
          }),
          method: "POST"
        });
        this.getLoading = false;


        if (status === 0) {
          this.articleForm = {
            articleTitle: result.articleTitle,
            articleName: result.articleName,
            articleContent: result.articleContent,
            questionTitle: result.title,
            questionName: result.name,
            questionContent: result.content,
            itemA: result.itemA,
            itemB: result.itemB,
            itemC: result.itemC,
            itemD: result.itemD,
            itemE: result.itemE,
            answer: result.answer,
            solution: result.solution
          };
        } else {
          this.$message.error("获取问题失败");
        }
      } catch (err) {
        this.getLoading = false;
        this.$message.error("获取问题失败");
      }
    },
    async saveArticle() {
      this.saveLoading = true;
      try {
        const url = "/saveQuestionByNumber";
        const {
          data: { data: result, status }
        } = await this.$http({
          method: "POST",
          url,
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify({
            actId: this.form.actId,
            type: this.form.actType.toLowerCase(),
            questionNumber: this.form.questionNumber,
            articleTitle: this.articleForm.articleTitle,
            articleName: this.articleForm.articleName,
            articleContent: this.articleForm.articleContent,
            questionTitle: this.articleForm.questionTitle,
            questionName: this.articleForm.questionName,
            questionContent: this.articleForm.questionContent,
            itemA: this.articleForm.itemA,
            itemB: this.articleForm.itemB,
            itemC: this.articleForm.itemC,
            itemD: this.articleForm.itemD,
            itemE: this.articleForm.itemE,
            answer: this.articleForm.answer,
            solution: this.articleForm.solution
          })
        });
        this.saveLoading = false;
        if (status === 1) {
          this.$message({
            message: "保存成功",
            type: "success"
          });
        } else {
          this.$message.error("保存失败");
        }
      } catch (err) {
        this.$message.error("保存失败");
        this.saveLoading = false;
      }
    },
    async saveAndPreviewArticle(){
      await this.saveArticle();
      const url = `${location.protocol}//${location.host}/cms/preview?actId=${this.form.actId}&actType=${this.form.actType.toLowerCase()}&questionNumber=${this.form.questionNumber}`;
      window.open(url);
    }
  }
};
</script>

<style lang="stylus">
.edit-question-body {
  width: 1180px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}


.edit-question-title {
  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.el-form{
  padding-right: 300px;
  }

.el-select {
  display: block!important;
}

.el-input.is-disabled .el-input__inner
  color: black !important

.el-form-item__content
  p
    color: #333;

  p+p
    margin-top 20px

  img
    vertical-align middle
    margin 0 5px

  u
    text-decoration none
    border-bottom 1px solid #333
    padding-bottom 3px

  u.upper-line
    text-decoration none
    border-top 1px solid #333
    padding-top 0px
    border-bottom none
    padding-bottom 3px
</style>
