<template>
  <div class="edit-question-body">
    <div class="edit-question-title">
      <h2>导出题目</h2>
      <p></p>
    </div>
    <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="ACT：" prop="actId">
        <el-select v-model="form.actId" placeholder="请选择ACT" filterable>
          <el-option
              v-for="item in actList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型：" prop="actType">
        <el-select v-model="form.actType" placeholder="请选择ACT类型">
          <el-option label="ENGLISH" value="ENGLISH"></el-option>
          <el-option label="MATH" value="MATH"></el-option>
          <el-option label="READING" value="READING"></el-option>
          <el-option label="SCIENCE" value="SCIENCE"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文章ID：" prop="questionNumber">
        <el-input
            v-model="form.questionNumber"
            type="Number"
            placeholder="请输入文章ID"
            :min="0"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getQuestionByNumber" :loading="getLoading">获取文章内容</el-button>
        <el-button type="primary" @click="exportWord" :loading="exportWordLoading">导出为Word</el-button>
      </el-form-item>
    </el-form>
    <el-form
        ref="articleForm"
        :model="articleForm"
        label-width="100px"
    >

      <el-form-item label="题目号：" prop="questionIds">
        <el-select v-model="form.questionIds" placeholder="请选择题目号" multiple>
          <el-option
              v-for="item in questionList"
              :key="item.number"
              :label="item.articleTitle"
              :value="item.number">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="文章题目：">
        <el-input v-model="articleForm.articleName"></el-input>
      </el-form-item>
      <!-- 文稿编辑区域 -->
      <el-form-item label="文章内容：">
        <!-- <vueEditor v-model="articleForm.articleContent"></vueEditor> -->
        <p v-html="articleForm.articleContent || '暂无内容'"></p>

      </el-form-item>


    </el-form>

    <editor-component
        ref="editorEle"
        v-model="propContent"
        @sure="handleSure"
    ></editor-component>
  </div>
</template>

<script>
import qs from "qs";

import EditorComponent from "../../components/EditorComponent";

export default {
  data() {
    return {
      propContent: "",
      propKey: "",
      getLoading: false,
      exportWordLoading: false,
      form: {
        actId: "",
        actType: "",
        questionNumber: "",
        questionIds: []
      },
      formRules: {
        actId: [{required: true, message: "请选择ACT", trigger: "change"}],
        actType: [{required: true, message: "请选择TYPE", trigger: "change"}],
        questionNumber: [
          {required: true, message: "请输入问题ID", trigger: "blur"}
        ]
      },
      articleForm: {
        articleTitle: "",
        articleName: "",
        articleContent: "",
        questionTitle: "",
        questionName: "",
        questionContent: "",
      },
      actList: [],
      questionList: []
    };
  },
  components: {EditorComponent},
  created() {
    this.getActList();
  },
  methods: {
    async exportWord() {
      var that = this
      if (this.form.questionIds.length > 0) {
        this.exportWordLoading = true;

        try {
          const url = "/exportWord";

          this.$http({
            url,
            data: qs.stringify({
              actId: this.form.actId,
              type: this.form.actType.toLowerCase(),
              questionNumber: this.form.questionNumber,
              questionNumbers: this.form.questionIds.toString()
            }),
            method: "POST",
            responseType: "blob"
            // eslint-disable-next-line no-unused-vars
          }).then(function (response) {
            that.exportWordLoading = false;

            const disposition = response.headers['content-disposition'];
            let fileName = disposition.substring(disposition.indexOf('filename=') + 9, disposition.length);


            const content = response.data;
            // 创建a标签并点击， 即触发下载
            let url = window.URL.createObjectURL(new Blob([content]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            // 模拟
            document.body.appendChild(link);
            link.click();
            // 释放URL 对象
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link)


          }, function () {
            that.exportWordLoading = false;
          })


        } catch (err) {
          this.$message.error(err);
          this.exportWordLoading = false;
          this.$message.error("导出Word失败");
        }
      } else {
        this.$message.error("请选择题目号");
      }
    },
    editArticleItem(key) {
      console.log(key);
      this.propKey = key;
      this.propContent = this.articleForm[key];
      this.$refs.editorEle.show();
    },
    handleSure(content) {
      this.articleForm = {
        ...this.articleForm,
        ...{
          [this.propKey]: content
        }
      };
      this.propKey = "";
      this.propContent = "";
    },
    async getActList() {
      try {
        const url = "/getAllActList";
        const {
          data: {data: result, status}
        } = await this.$http({
          method: "POST",
          url,
          headers: {"content-type": "application/x-www-form-urlencoded"},
          data: {}
        });
        if (status === 0) {
          this.actList = result;
        } else {
          // console.error();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getQuestionByNumber() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getQuestionByNumberReq();
        } else {
          return false;
        }
      });
    },
    async getQuestionByNumberReq() {
      this.getLoading = true;

      try {
        const url = "/getQuestionByNumberAndList";
        const {
          data: {data: result, status}
        } = await this.$http({
          url,
          data: qs.stringify({
            actId: this.form.actId,
            type: this.form.actType.toLowerCase(),
            questionNumber: this.form.questionNumber
          }),
          method: "POST"
        });
        this.getLoading = false;

        if (status === 0) {
          this.form.questionIds = []
          this.articleForm = {
            articleTitle: result.question.articleTitle,
            articleName: result.question.articleName,
            articleContent: result.question.articleContent,
            questionTitle: result.question.title,
            questionName: result.question.name,
            questionContent: result.question.content,
            solution: result.solution
          };
          this.questionList = result.questionList
        } else {
          this.$message.error("获取问题失败");
        }
      } catch (err) {
        this.getLoading = false;
        this.$message.error("获取问题失败");
      }
    }
  }
};
</script>

<style lang="stylus">
.edit-question-body {
  width: 1180px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}


.edit-question-title {
  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.el-form {
  padding-right: 300px;
}

.el-select {
  display: block !important;
}

.el-input.is-disabled .el-input__inner
  color: black !important

.el-form-item__content
  p
    color: #333;

  p + p
    margin-top 20px

  img
    vertical-align middle
    margin 0 5px

  u
    text-decoration none
    border-bottom 1px solid #333
    padding-bottom 3px

  u.upper-line
    text-decoration none
    border-top 1px solid #333
    padding-top 0px
    border-bottom none
    padding-bottom 3px
</style>
