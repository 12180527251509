<template>
  <div class="act-list-body">
    <div class="act-title">
      <h2>{{ actName }}词汇一览</h2>
      <p></p>
    </div>

    <main class="act-main">
      <el-table v-loading="loading" :data="vocabularyList" border style="width: 100%">
        <el-table-column label="词汇">
          <template slot-scope="scope">
            <p>{{ scope.row.word }}</p>
          </template>
        </el-table-column>
        <el-table-column label="解释">
          <template slot-scope="scope">
            <p v-html="scope.row.translate"></p>
          </template>
        </el-table-column>
        <el-table-column label="所在题目">
          <template slot-scope="scope">
            <p>{{ scope.row.subId | getSubName }}</p>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import qs from "qs";
import { getParams } from "../../assets/js/util";

export default {
  data() {
    return {
      vocabularyList: [],
      actName: "",
      loading: true
    };
  },
  components: {},
  filters: {
    getSubName(subId) {
        if (subId == 1){
          return "English";
        } else if (subId == 2){
          return "R1";
        } else if (subId == 3){
          return "R2";
        } else if (subId == 4){
          return "R3";
        } else if (subId == 5){
          return "R4";
        }
      return ""
    },
  },
  created() {
    const query = getParams(window.location.href);
    let actId = query.actId
    this.actName = decodeURIComponent(query.actName)
    this.getVocabularyList(actId);
  },
  methods: {
    async getVocabularyList(actId) {
      try {
        const url = "/getVocabularyByActId";
        const {
          data: { data: vocabularyListResult, status }
        } = await this.$http({
          url,
          data: qs.stringify({
            actId: actId
          }),
          method: "POST"
        });
        console.log("result", vocabularyListResult);

        if (status === 0) {
          this.vocabularyList = vocabularyListResult;
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.act-list-body {
  width: 1180px;
  min-height 100vh
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}

.act-title {

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.act-main {
  margin-top: 40px;
}

.act-pagination {
  margin-top: 20px;
}
</style>
