<template>
  <div class="cont-body" v-loading="loading">
    <el-form label-width="80px" style="margin-top: 32px">
      <el-form-item label="Passage" prop="passage">
        <el-select v-model="readId" placeholder="请选择" @change="readIdChange">
          <el-option
            v-for="item in passageList"
            :key="item.value"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row style="margin-top: 32px">
      <el-button type="primary" @click="newOpen">新增试题</el-button>
    </el-row>
    <el-table :data="tableData" style="width: 100%; margin-top: 24px" border>
      <el-table-column type="index" label="编号" width="50"> </el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <p v-html="scope.row.content"></p>
        </template>
      </el-table-column>
      <el-table-column label="Passage">
        <template>
          <p v-html="passageCont"></p>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="答案" width="80px">
      </el-table-column>
      <el-table-column label="操作" width="140px">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            type="text"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            icon="el-icon-delete"
            type="text"
            class="delete"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加或修改文章对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="900px"
      append-to-body
      @close="cancel"
    >
      <el-form label-width="80px">
        <el-form-item label="Passage" prop="passage">
          <el-select
            v-model="passageId"
            placeholder="请选择"
            @change="passageChange"
          >
            <el-option
              v-for="item in passageList"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章内容">
          <vueEditor v-model="readContent" :height="300"></vueEditor>
        </el-form-item>
        <el-form-item label="问题内容">
          <vueEditor v-model="content" :height="200"></vueEditor>
        </el-form-item>
        <el-form-item label="选题A" prop="itemA">
          <el-input v-model="itemA" placeholder="请输入选题A" />
        </el-form-item>
        <el-form-item label="选题B" prop="itemB">
          <el-input v-model="itemB" placeholder="请输入选题B" />
        </el-form-item>
        <el-form-item label="选题C" prop="itemC">
          <el-input v-model="itemC" placeholder="请输入选题C" />
        </el-form-item>
        <el-form-item label="选题D" prop="itemD">
          <el-input v-model="itemD" placeholder="请输入选题D" />
        </el-form-item>
        <el-form-item label="答案" prop="answer">
          <el-input v-model="answer" placeholder="请输入答案" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定 </el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
import { find } from "loadsh";
export default {
  data() {
    return {
      tableData: [],
      passageList: [],
      passageId: "",
      title: "新增试题",
      open: false,
      content: "",
      readContent: "", // 文章内容
      itemA: "",
      itemB: "",
      itemC: "",
      itemD: "",
      answer: "",
      readId: "",
      loading: false
    };
  },
  computed: {
    passageCont() {
      const cont = find(this.passageList, item => item.id === this.readId);
      if (cont) {
        return cont.name;
      }
      return "";
    }
  },
  methods: {
    readIdChange() {
      this.getReadQuesList(this.readId);
    },
    async getReadQuesList(readId) {
      this.loading = true;
      try {
        const url = "/readQuestion/list";
        const {
          data: { data: tableData, status }
        } = await this.$http({
          url,
          params: { readId: readId },
          method: "GET"
        });

        if (status === 0) {
          this.tableData = tableData;
        } else {
          this.$message.error("获取问题失败");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async getReadList() {
      this.loading = true;
      try {
        const url = "/read/list";
        const {
          data: { data: passageList, status }
        } = await this.$http({
          url,
          method: "GET"
        });

        if (status === 0) {
          this.passageList = passageList;
          this.loading = false;
          if (this.passageList.length) {
            this.readId = this.passageList[0].id;
            this.getReadQuesList(this.readId);
          }
        } else {
          this.$message.error("获取问题失败");
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    passageChange() {
      const cont = find(this.passageList, item => item.id === this.passageId);
      if (cont) this.readContent = cont.content;
    },
    newOpen() {
      this.open = true;
    },
    handleEdit(index, row) {
      this.title = "修改试题";
      this.passageId = row.readId;
      this.passageChange();
      this.content = row.content;
      this.answer = row.answer;
      this.id = row.id;
      this.itemA = row.itemA;
      this.itemB = row.itemB;
      this.itemC = row.itemC;
      this.itemD = row.itemD;
      this.open = true;
    },
    async handleDelete(index, row) {
      // 删除试题
      try {
        const url = "/readQuestion/remove";
        const {
          data: { status }
        } = await this.$http({
          url,
          data: qs.stringify({
            id: row.id
          }),
          method: "POST"
        });

        if (status === 0) {
          this.getReadQuesList(this.readId);
        } else {
          this.$message.error("删除失败");
        }
      } catch (err) {
        this.$message.error("删除失败");
      }
    },
    async submitForm() {
      if (!this.passageId) {
        this.$message.warning("请选择passage");
        return;
      }
      if (!this.content) {
        this.$message.warning("请输入问题内容");
        return;
      }
      if (!this.itemA && !this.itemB && !this.itemC && !this.itemD) {
        this.$message.warning("请输入选项");
        return;
      }
      if (!this.answer) {
        this.$message.warning("请输入问题答案");
        return;
      }
      this.loading = true;
      try {
        const url = this.id ? "/readQuestion/update" : "/readQuestion/save";
        const {
          data: { status }
        } = await this.$http({
          url,
          data: qs.stringify({
            readId: this.passageId,
            content: this.content,
            answer: this.answer,
            itemA: this.itemA,
            itemB: this.itemB,
            itemC: this.itemC,
            itemD: this.itemD,
            id: this.id ? this.id : undefined
          }),
          method: "POST"
        });

        if (status === 0) {
          this.readId = this.passageId;
          this.cancel();
          this.loading = false;
          this.getReadQuesList(this.readId);
        } else {
          this.$message.error("保存失败");
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
        this.$message.error("保存失败");
      }
    },
    cancel() {
      this.title = "新增试题";
      this.passageId = "";
      this.readContent = "";
      this.content = "";
      this.answer = "";
      this.id = "";
      this.itemA = "";
      this.itemB = "";
      this.itemC = "";
      this.itemD = "";
      this.open = false;
    }
  },
  created() {
    this.getReadList();
  }
};
</script>

<style lang="stylus" scoped>
.cont-body{
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff
}
/deep/ .el-form{
  padding-right: 0
}
/deep/ .delete{
  color: #f56c6c!important;
}
/deep/ .delete:hover{
  color: #f56c6c!important;
}
</style>
