<template>
  <div class="act-list-body">
    <div class="act-title">
      <h2>ACT记录搜索</h2>
      <p></p>
    </div>

    <el-form ref="form" :inline="true" :model="form" label-width="90px">


      <el-form-item label="内容检索：" prop="content">
        <el-input
            v-model="form.content"
            placeholder="请输入单词"
        ></el-input>
      </el-form-item>

            <el-form-item label="类型：" prop="actType" >
              <el-select v-model="form.actType" placeholder="请选择ACT类型" @change="getQuestionList">
                <el-option label="ENGLISH" value="english"></el-option>
                <el-option label="MATH" value="math"></el-option>
                <el-option label="READING" value="reading"></el-option>
                <el-option label="SCIENCE" value="science"></el-option>
              </el-select>
            </el-form-item>

<!--      <el-form-item label="问题内容：" prop="questionContent">-->
<!--        <el-input-->
<!--            v-model="form.questionContent"-->
<!--            placeholder="请输入问题内容"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary"  @click="getQuestionList" :loading="getLoading">查询</el-button>
      </el-form-item>
    </el-form>

    <main class="act-main">
      <el-table v-loading="loading" :data="examList" border style="width: 100%">

        <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
        <el-table-column prop="actName" label="ACT" width="150" align="center"></el-table-column>
        <el-table-column label="科目" width="100" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.type.toUpperCase() }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="题号" width="150" align="center"></el-table-column>
        <el-table-column prop="articleName" label="文章名称" width="250" align="center"></el-table-column>
        <el-table-column prop="title" label="问题内容" width="350" align="center"></el-table-column>
        <el-table-column fixed="right" label="详情" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="gotoSingleResult(scope.row)" type="text" size="small">
              进入
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </main>

    <div class="act-pagination" v-if="examList.length > 0">
      <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :pager-count="21"
          :hide-on-single-page="true"
          @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import {getParams, formatDiffDate} from "../../assets/js/util";

export default {
  data() {
    return {
      userId: 0,
      getLoading: false,
      examList: [],
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      loading: false,
      form: {
        actId: "",
        actType: "",
        questionNumber: "",
        content: "",

      }
    };
  },
  components: {},
  filters: {
    formatDuration(duration) {
      const minute = parseInt(duration / 60);
      const second = duration % 60;
      console.log(minute + ":" + second)
      return `${minute > 10 ? minute : `0${minute}`}:${
          second >= 10 ? second : `0${second}`
      }`;
    },
    getTimestamp(time) {
      if (time === null) {
        return "-";
      }
      return formatDiffDate(new Date(time).getTime() / 1000, "-", true);
    }
  },
  created() {
    this.getQuestionList();
  },
  methods: {
    async getQuestionList() {
      this.getLoading = true
      try {
        const url = "/getQuestionList";
        const {
          data: {data: examListResult, status}
        } = await this.$http({
          url,
          data: qs.stringify({
            pageNumber: this.pageNumber,
            content: this.form.content,
            type: this.form.actType,
          }),
          method: "POST"
        });
        console.log("result", examListResult);

        if (status === 0) {
          this.examList = examListResult.list;
          this.total = examListResult.total;
        } else {
          // console.error();
        }
        this.getLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    currentChange(val) {
      this.pageNumber = val;
      this.getQuestionList();
    },
    gotoActAllSubjectTestingResult(row) {
      console.log("row", row);
      this.$router.push({
        path: "/actAllSubjectTestingResult",
        query: {
          examId: 0,
          userId: this.userId,
          actAllSubjectTestingId: row.actAllSubjectTestingId
        }
      });
    },
    gotoSingleResult(row) {
      console.log("row", row);
      let routeUrl = this.$router.resolve({
        path: "/cms/editQuestion",
        query: {
          actId: row.actId,
          type: row.type,
          number: row.number
        }
      });

      window.open(routeUrl.href,"_blank");
    },
    gotoDeleteResult(row) {
      console.log("row", row);
      this.$confirm('这个单项⼤部分题⽬你都没有做，要删除这次记录吗？', "删除考试记录").then(_ => {
        this.deleteExamById(row.id)
      }).catch(_ => {
        console.log("2");
      })
    },
    async deleteExamById(id) {

      try {
        const url = "/deleteExamById";
        const {
          data: {data: result, status}
        } = await this.$http({
          url,
          data: qs.stringify({
            id: id
          }),
          method: "POST"
        });
        console.log("result", result);

        if (status === 0) {
          this.getQuestionList();
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.act-list-body {
  width: 1180px;
  min-height 100vh
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}

.act-title {

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.act-main {
  margin-top: 40px;
}

.act-pagination {
  margin-top: 20px;
}
</style>
