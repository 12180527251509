<template>
  <div class="act-list-body">
    <div class="act-title">
      <h2>ACT真题</h2>
    </div>

    <div style="margin-top: 20px">
      <div
        style="display: inline; margin: 10px"
        v-for="(actType, key) in actTypeList"
        :key="key"
      >
        <el-button type="primary" @click="getActList(actType.id)"
          >{{ actType.name }}({{ actType.actNumber }})
        </el-button>
      </div>
    </div>

    <main class="act-main">
      <el-table v-loading="loading" :data="actList" border style="width: 100%">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="english" label="ENGLISH">
          <template slot-scope="scope">
            <el-button type="text" size="small">
              <span
                class="gree-scope"
                v-if="scope.row.english"
                @click="handleClick(scope.row.english, 7, 1)"
              >
                <b>{{ scope.row.english.score }}</b>
                <font> {{ scope.row.english.rightQuestions }}/75</font>
                <!--   {{ scope.row.english.time }}-->
              </span>
              <font v-else @click="handleClick(scope.row, 1)"> English </font>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="math" label="MATH">
          <template slot-scope="scope">
            <el-button type="text" size="small">
              <span
                class="gree-scope"
                v-if="scope.row.math"
                @click="handleClick(scope.row.math, 7, 2)"
              >
                <b>
                  {{ scope.row.math.score }}
                </b>
                <font>{{ scope.row.math.rightQuestions }}/60</font>
                <!--  {{ scope.row.math.time }}-->
              </span>
              <font v-else @click="handleClick(scope.row, 2)">Math</font>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="reading" label="READING">
          <template slot-scope="scope">
            <el-button type="text" size="small">
              <span
                class="gree-scope"
                v-if="scope.row.reading"
                @click="handleClick(scope.row.reading, 7, 3)"
              >
                <b>
                  {{ scope.row.reading.score }}
                </b>
                <font> {{ scope.row.reading.rightQuestions }}/40</font>
                <!--   {{ scope.row.reading.time }}-->
              </span>
              <font v-else @click="handleClick(scope.row, 3)">Reading</font>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="science" label="SCIENCE">
          <template slot-scope="scope">
            <el-button type="text" size="small">
              <span
                class="gree-scope"
                v-if="scope.row.science"
                @click="handleClick(scope.row.science, 7, 4)"
              >
                <b>
                  {{ scope.row.science.score }}
                </b>

                <font>{{ scope.row.science.rightQuestions }}/40</font>

                <!--  {{ scope.row.science.time }}-->
              </span>
              <font v-else @click="handleClick(scope.row, 4)">Science</font>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="COMPOSITE">
          <template slot-scope="scope">
            <el-button type="text" size="small">
              <span
                class="gree-scope"
                v-if="scope.row.composite"
                @click="handleClick(scope.row.composite, 7, 5)"
              >
                <b>{{ scope.row.composite.compositeScore }}</b>
                <!--    {{ formatDate(scope.row.composite.created_datetime) }}-->
              </span>
              <font v-else @click="handleClick(scope.row, 5)"> Composite </font>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="词汇">
          <template slot-scope="scope">
            <el-button
              @click="gotoWordsPage(scope.row)"
              type="text"
              size="small"
              ><font> 词汇 </font>
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="600">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, 1)"
              type="text"
              size="small"
            >
              ENGLISH </el-button
            ><el-button
              @click="handleClick(scope.row, 2)"
              type="text"
              size="small"
            >
              MATH </el-button
            ><el-button
              @click="handleClick(scope.row, 3)"
              type="text"
              size="small"
            >
              READING </el-button
            ><el-button
              @click="handleClick(scope.row, 4)"
              type="text"
              size="small"
            >
              SCIENCE
            </el-button>
            <el-button
              @click="handleClick(scope.row, 5)"
              type="text"
              size="small"
            >
              COMPOSITE
            </el-button>
            <el-button
                @click="gotoWordsPage(scope.row)"
                type="text"
                size="small"
            >
              词汇
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </main>

    <div class="act-pagination" v-if="actList.length > 0 && actTypeId == 0">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <Dialog ref="dialog"></Dialog>
  </div>
</template>

<script>
import qs from "qs";
import { getParams } from "../../assets/js/util";
import Dialog from "../../components/indexDialog.vue";

export default {
  data() {
    return {
      userId: 0,
      actList: [],
      actTypeList: [],
      total: 0,
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      actTypeId: 0
    };
  },
  components: {
    Dialog
  },
  created() {
    const query = getParams(window.location.href);
    this.userId = query.userId;

    console.log(this.userId);
    if (this.userId === undefined) {
      this.$router.push(`/trial`);
    } else {
      this.getActList(0);
      this.getActTypeList();
    }
  },
  methods: {
    formatDate(millinSeconds) {
      var date = new Date(millinSeconds);
      var monthArr = new Array(
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Spt",
        "Oct",
        "Nov",
        "Dec"
      );
      var suffix = new Array("st", "nd", "rd", "th");

      var year = date.getFullYear(); //年
      var month = monthArr[date.getMonth()]; //月
      var ddate = date.getDate(); //日

      if (ddate % 10 < 1 || ddate % 10 > 3) {
        ddate = ddate + suffix[3];
      } else if (ddate % 10 == 1) {
        ddate = ddate + suffix[0];
      } else if (ddate % 10 == 2) {
        ddate = ddate + suffix[1];
      } else {
        ddate = ddate + suffix[2];
      }
      return ddate + " " + month;
    },

    async getActList(actTypeId) {
      this.actTypeId = actTypeId;
      try {
        const url = "/getActList";
        const {
          data: { data: actListResult, status, total }
        } = await this.$http({
          url,
          data: qs.stringify({
            actTypeId: actTypeId,
            username: this.userId,
            pageNumber: this.pageNumber
          }),
          method: "POST"
        });

        if (status === 0) {
          let list = [];
          actListResult.map(el => {
            if (el.composite) {
              el.composite.id = el.id;
              el.composite.actId = el.id;
              el.composite.userId = this.userId;
              el.composite.type = "composite";
              el.exams.map(v => {
                console.log(v);
                el[v.type] = {
                  score: v.score,
                  time: this.formatDate(v.createdDatetime),
                  rightQuestions: v.rightQuestions,
                  type: v.type,
                  actId: v.actId,
                  userId: v.userId,
                  id: el.id,
                  name: el.name
                };
              });
            } else {
              el.exams.map(v => {
                console.log(v);
                el[v.type] = {
                  score: v.score,
                  time: this.formatDate(v.createdDatetime),
                  rightQuestions: v.rightQuestions,
                  type: v.type,
                  actId: v.actId,
                  userId: v.userId,
                  id: el.id,
                  name: el.name
                };
              });
            }
            list.push(el);
          });
          this.actList = list;
          this.total = total;
          console.log(this.total);
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getActTypeList() {
      try {
        const url = "/getActTypeList";
        const {
          data: { data: actTypeListResult, status }
        } = await this.$http({
          url,
          data: qs.stringify({}),
          method: "POST"
        });

        if (status === 0) {
          this.actTypeList = actTypeListResult;
        } else {
          // console.error();
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    currentChange(val) {
      this.pageNumber = val;
      this.getActList(0);
    },
    handleClick(row, type, type1) {
      if (type == 7) {
        console.log(this.$refs);
        row.userId = this.userId;
        this.$refs.dialog.open(row, type1);
      } else {
        console.log(row);
        const url = `${location.protocol}//${location.host}/start?userId=${
          this.userId
        }&actId=${row.id}&type=${type}&title=${encodeURIComponent(row.name)}`;
        this.$refs.dialog.close();
        window.open(url);
      }
    },
    gotoWordsPage(row) {
      console.log("row", row);
      const url = `${location.protocol}//${location.host}/vocabulary?actId=${
        row.id
      }&actName=${encodeURIComponent(row.name)}`;
      window.open(url);
    }
  }
};
</script>
<style lang="stylus">
.el-button--text {
  b {
    font-size: 16px;
  }

  font {
    font-size: 16px;
    margin-left 12px;
  }
}
</style>
<style lang="stylus" scoped>
.act-list-body {
  width: 1180px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}

.act-title {
  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
  }

  p {
    font-size: 15px;
    color: #666;
  }
}

.act-main {
  margin-top: 40px;
}

.act-pagination {
  margin-top: 20px;
}
.gree-scope{
  color: green
}
</style>
