<template>
  <div class="result-body">
    <div class="result-header">
      <div class="act-logo"></div>
      <div class="backFont">返回主页</div>
      <div class="backTo" @click="backHistory"></div>
    </div>
    <h2 class="result-title">Result</h2>
    <div class="result-main">
      <el-row>
        <el-col :span="24">
          <p class="exam-title">{{ actName }}</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Number of questions</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value" v-if="actList.length > 0">
            {{ actList.length }}
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Date</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value" v-if="testDate">
            {{ testDate | getTimestamp }}
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Duration</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value" v-if="duration">
            {{ duration | formatDuration }}
          </p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="24">
          <p class="exam-result-title">Your test is complete</p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Section</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value">
            {{ section }}
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Total result</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value">
            {{ rightQuestion }} / {{ countOfQuestion }}
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p class="exam-table-label">Score</p>
        </el-col>
        <el-col :span="8">
          <p class="exam-table-value" v-show="parseInt(score)">
            {{ score }}
          </p>
          <el-tooltip
            effect="dark"
            content="该套真题的算分表数据缺失"
            placement="top-start"
            v-show="!parseInt(score)"
          >
            <span class="exam-table-value">Not availble</span>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="24">
          <p class="exam-title">Answer Listing</p>
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        stripe
        :data="actList"
        border
        :height="tableHeight"
        style="width: 100%"
      >
        <el-table-column align="center" width="150" label="Item Number">
          <template slot-scope="scope">
            <p>Question {{ scope.row.number }}</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          prop="articleTitle"
          label="Passage"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="testing"
          label="考点/归类"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="answer"
          label="Answer"
        ></el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="userAnswer"
          label="Your Answer"
        ></el-table-column>
        <el-table-column align="center" width="120">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="seltctValue"
              size="mini"
              placeholder="请选择"
              @change="resultChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template slot-scope="scope">
            <p v-if="scope.row.userAnswer === ''" style="color: #606266;">
              Unanswered
            </p>
            <p
              v-else-if="scope.row.userAnswer === scope.row.answer"
              style="color:#67C23A;"
            >
              Right
            </p>
            <p v-else style="color:#F56C6C;">Wrong</p>
          </template>
        </el-table-column>
        <el-table-column align="center" width="120" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">
              Answer Analysis
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { getParams, formatDiffDate } from "../../assets/js/util";
import { cloneDeep } from "loadsh";

export default {
  data() {
    return {
      originActList: [],
      actList: [],
      duration: "",
      score: "",
      actName: "",
      rightQuestion: "",
      countOfQuestion: "",
      section: "",
      testDate: "",
      loading: true,
      options: [
        { value: "1", name: "all" },
        { value: "2", name: "not right" }
      ],
      seltctValue: "1",
      tableHeight: 994
    };
  },
  components: {},
  created() {
    const query = getParams(window.location.href);
    this.userId = query.userId;
    this.examId = query.examId;

    this.getResultList();
    window.addEventListener("scroll", this.scrollFn);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollFn);
  },
  filters: {
    formatDuration(duration) {
      const minute = parseInt(duration / 60);
      const second = duration % 60;
      return `${minute > 10 ? minute : `0${minute}`}:${
        second > 10 ? second : `0${second}`
      }`;
    },
    getTimestamp(time) {
      return formatDiffDate(new Date(time).getTime() / 1000, "-", true);
    }
  },
  methods: {
    // 吸顶函数
    scrollFn() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.tableHeight = scrollTop > 480 ? window.innerHeight - 10 : 994;
    },
    // 结果筛选
    resultChange() {
      if (parseInt(this.seltctValue) === 1) {
        this.actList = this.originActList;
      } else {
        this.actList = this.originActList.filter(item => {
          if (item.userAnswer !== item.answer || !item.userAnswer) {
            return item;
          }
        });
      }
    },
    backHistory() {
      this.$router.go(-1);
    },
    handleClick(row) {
      const url = `${location.protocol}//${location.host}/exam?examId=${this.examId}&userId=${this.userId}&step=3&questionId=${row.questionId}&isPreview=1`;
      window.open(url);
    },
    async getResultList() {
      try {
        const url = "/getResultList";
        const {
          data: { data: resultList, status }
        } = await this.$http({
          url,
          data: qs.stringify({
            username: this.userId,
            examId: this.examId
          }),
          method: "POST"
        });

        if (status === 0) {
          this.originActList = resultList.getQuestionResponseList;
          this.actList = cloneDeep(this.originActList);
          this.countOfQuestion = resultList.countOfQuestion;
          this.duration = resultList.duration;
          this.score = resultList.score;
          this.actName = resultList.actName;
          this.rightQuestion = resultList.rightQuestion;
          this.countOfQuestion = resultList.countOfQuestion;
          this.section = resultList.section;
          this.testDate = resultList.testDate;
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.el-row
  margin-bottom: 10px;

  &:last-child
    margin-bottom: 0;



.result-body
  width 940px
  min-height 100vh
  margin 0 auto
  background #fff

  .result-header
    width 100%
    height 73px
    background #001733
    display flex
    align-items center

    .act-logo
      width 120px
      height 27px
      margin-left 25px
      background url('./../../assets/img/logo.png') 0 0 no-repeat

    .backTo
      cursor pointer
      width 50px
      height: 30px;
      background url('./../../assets/img/back.png')  0 0 no-repeat
      background-size 30px 25px
      position: absolute;
      left: 72%;

    .backFont
      color #B1B1B1
      height: 25px;
      position: absolute;
      left: 68%;
      font-weight: bold




  .result-title
    display flex
    align-items center
    height 67px
    font-size 22px
    color #333
    border-bottom 3px solid #e42a22
    padding-left 25px

  .result-main
    padding 40px 30px 20px

  .exam-title
    color #333
    font-size 17px
    font-weight bold

  .exam-table-label
    font-size 13px
    color #333
    font-weight bold

  .exam-table-value
    font-size 13px
    color #333
    font-weight normal

  .exam-result-title
    color #333
    font-size 24px
    font-weight bold
</style>
